import { useState, useEffect } from 'react'
const breakpoints = {
	mobile: 767,
	tablet: 1199,
}
export const usePictureMode = () => {
	function getSize() {
		return window.innerWidth >=
			breakpoints.mobile &&
			window.innerWidth <= breakpoints.tablet
			? 'Tab'
			: window.innerWidth > breakpoints.tablet
			? ''
			: 'Mob'
	}

	const [windowSize, setWindowSize] =
		useState(getSize)
	// window.onload
	useEffect(() => {
		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener(
			'resize',
			handleResize
		)
		return () => {
			window.removeEventListener(
				'resize',
				handleResize
			)
		}
	}, [])

	return windowSize
}
