import s from './SouthPole.module.scss'
import {
	usePageName,
	useWindowMode,
	usePictureMode,
} from 'utils'
import Img from 'components/Img/Img'
import { useTranslation } from 'react-i18next'
import SwitchTwoImages from 'components/SwitchTwoImages/SwitchTwoImages'
import { Slider1 } from 'components/Sliders/Slider1'
import { Switcher as DeskSwitcher } from 'components/Sliders/Desk/Switcher'
import { Switcher as DeskSwitcher2 } from 'components/Sliders/Desk/SwitcherFiveImages'
import { SwitcherTab as TabletSwitcher } from 'components/Sliders/Tab/SwitcherTab'
import {
	SliderVerticalHorizontal,
	SliderVerticalHorizontal as SwitchVH
} from "../../components/Sliders/SliderVerticalHorizontal";
import AuthorLayout from 'components/AuthorLayout/AuthorLayout'
import Quotes from 'components/Quotes/Quotes'
import { Divider } from 'components/Divider'
import FadeInSection from 'components/FadeInOut/FadeSection'
import {Slider2Tab} from "../../components/Sliders/Slider2/Slider2Tab";
import CustomSliderTab from "../../components/Sliders/NewSliders/Tablet/Tablet";
import CustomSliderMobile from "../../components/Sliders/NewSliders/Mobile";
import {SliderVHTablet} from "../../components/Sliders/SliderVHTablet";
// import { Player } from 'components/VideoJS/Player'

const SouthPole = () => {
	const { t } = useTranslation()
	const pageName = usePageName()
	const size = useWindowMode()
	const tr = slice => {
		return t(`pages.${pageName}.${slice}`)
	}

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName

	const fourthSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'fourth',
		slides: 8,
	}
	const fifthSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'fifth',
		slides: 8,
	}
	const eleventhSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'eleventh',
		slides: 4,
	}

	return (
		<main className={` ${s.page} `}>
			<FadeInSection>
				<section className={s.pagePreview}>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
						alt={tr('first_section.alts.image1')}
					/>
					<h1 className={s.title}>
						{tr('first_section.title')}
					</h1>
					<div className={s.coordinates}>
						<img
							src='/icons/LOCATION_ICON.svg'
							alt='LOCATION ICON'
						/>
						<h2>
							{tr('first_section.coordinates')}
						</h2>
					</div>
					<div className={s.height}>
						<img
							src='/icons/altitude_icon.svg'
							alt='altitude ICON'
						/>
						<h2>{tr('first_section.height')}</h2>
					</div>
				</section>
			</FadeInSection>

			<Divider d='140' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.second} `}>
					<h3>{tr('second_section.title')}</h3>
					<SwitchTwoImages
						pageName={pageName}
						section={'second'}
					/>
				</section>
			</FadeInSection>
			<Divider d='190' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.third} `}>
					<h3>{tr('third_section.title')}</h3>
					<p
					// className={` ${s.} `}
					>
						{tr('third_section.first_paragraph')}
					</p>
					<figure>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/ThirdSectionImage1${mode}.jpg`}
							alt={tr(
								'third_section.alts.image1'
							)}
						/>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/ThirdSectionImage2${mode}.jpg`}
							alt={tr(
								'third_section.alts.image2'
							)}
						/>
						<figcaption>
							{tr('third_section.description')}
						</figcaption>
					</figure>
					<p
						className={` ${s.second_paragraph} `}
					>
						{tr('third_section.second_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<Divider d='50' t='39' m='40' />
			<FadeInSection>
				<section className={` ${s.fourth} `}>
					{size === 'desktop' ? (
						<DeskSwitcher
							{...fourthSliderConfig}
							highActive
						/>
					) : size === 'tablet' ? (
						<SliderVHTablet
						 {...fourthSliderConfig} showCounter/>
					) : (
						<CustomSliderMobile {...fourthSliderConfig} id={"fourth_section_slider"} borderSliders={3} />
					)}
					<p>
						{tr('fourth_section.first_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<Divider d='50' t='40' m='40' />
			<FadeInSection>
				<section className={` ${s.fifth} `}>
					{size === 'desktop' ? (
						<SwitchVH
							className='slider'
							page={pageName}
							section={'fifth'}
							noDots
							fromStart
							slides={8}
						/>
					) : size === 'tablet' ? (
						<CustomSliderTab {...fifthSliderConfig} id={"fifth_section_slider"} borderSliders={3} isLinear/>
					) : (
						<CustomSliderMobile {...fifthSliderConfig} id={"fifth_section_slider"} borderSliders={3} hidePull isLinear isArrows/>
					)}
				</section>
			</FadeInSection>
			<Divider d='190' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.sixth} `}>
					<h3>{tr('sixth_section.title')}</h3>
					<p>
						{tr('sixth_section.first_paragraph')}
					</p>
					<SwitchTwoImages
						pageName={pageName}
						section={'sixth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={` ${s.seventh} `}>
					<p
					// className={` ${s.} `}
					>
						{tr(
							'seventh_section.first_paragraph'
						)}
					</p>
					<figure>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/SeventhSectionBg${mode}.jpg`}
							alt={tr(
								'seventh_section.image1.alt'
							)}
							fullScreenMode
						/>

						<figcaption>
							{tr(
								'seventh_section.image1.description'
							)}
						</figcaption>
					</figure>
					<p
					// className={` ${s.} `}
					>
						{tr(
							'seventh_section.second_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='160' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.eighth} `}>
					<h3>{tr('eighth_section.title')}</h3>
					<h4>
						{tr('eighth_section.first_paragraph')}
					</h4>
					<AuthorLayout
						pageName={pageName}
						section={'Eighth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='50' t='78' m='43' />
			<FadeInSection>
				<section className={` ${s.ninth} `}>
					<Quotes
						top
						pageName={pageName}
						section={'ninth'}
						paragraph={'first'}
					/>
					<figure>
						<Img
							className={s.image}
							// Андрей это из-за тебя. Я переименовывал картинки когда увидел твой свичер и случайно переименовал и эти. Оставлю их так
							src={`${basePicturesUrl}/jpg/NinthSectionImg1${mode}.jpg`}
							alt={tr(
								'ninth_section.alts.image1'
							)}
							fullScreenMode
						/>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/NinthSectionImg2${mode}.jpg`}
							alt={tr(
								'ninth_section.alts.image2'
							)}
							fullScreenMode
						/>
						<figcaption>
							{tr('ninth_section.description')}
						</figcaption>
					</figure>
					<Quotes
						bottom
						pageName={pageName}
						section={'ninth'}
						paragraph={'second'}
					/>
				</section>
			</FadeInSection>
			<Divider d='157' t='97' m='100' />
			<FadeInSection>
				<section className={` ${s.tenth} `}>
					<h3>{tr('tenth_section.title')}</h3>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TenthSectionBg${mode}.jpg`}
						alt={tr('ninth_section.alts.image1')}
					/>
					<p
					// className={` ${s.} `}
					>
						{tr('tenth_section.first_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<Divider d='50' t='40' m='40' />
			<FadeInSection>
				<section className={` ${s.eleventh} `}>
					{size === 'desktop' ? (
						<DeskSwitcher
							{...eleventhSliderConfig}
						/>
					) : size === 'tablet' ? (
						<TabletSwitcher
							whiteArrows
							showPull
							{...eleventhSliderConfig}
						/>
					) : (
						<CustomSliderMobile {...eleventhSliderConfig} id={"eleventh_section_slider"} borderSliders={3} isDots isArrows/>
					)}
					<p
					// className={` ${s.} `}
					>
						{tr(
							'eleventh_section.first_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='100' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.twelfth} `}>
					<AuthorLayout
						pageName={pageName}
						section={'Twelfth'}
					/>
					<Quotes
						top
						pageName={pageName}
						section={'twelfth'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>
				<section className={` ${s.thirteenth} `}>
					<figure>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/ThirteenthSectionImage1${mode}.jpg`}
							alt={tr(
								'thirteenth_section.alts.image1'
							)}
							fullScreenMode
						/>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/ThirteenthSectionImage2${mode}.jpg`}
							alt={tr(
								'thirteenth_section.alts.image2'
							)}
							fullScreenMode
						/>
						<figcaption>
							{tr(
								'thirteenth_section.description'
							)}
						</figcaption>
					</figure>
					<Quotes
						bottom
						pageName={pageName}
						section={'thirteenth'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='177' t='125' m='60' />
		</main>
	)
}

export default SouthPole
