import "./AuthorLayout.scss";
import { withTranslation } from "react-i18next";
import { usePictureMode } from "../../utils/usePictureMode";
import "video.js/dist/video-js.css";
import Img from "components/Img/Img";
import { capitalize } from '../../utils/utils'
import Quotes from 'components/Quotes/Quotes'

const AuthorLayout = ({
	t,
	pageName,
	section,
	fix,
	quotes,top,bottom
}) => {
	const tr = slice =>
		t(`pages.${pageName}.${slice}`)

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName

	const lower = function () {
		return section.toLowerCase()
	}

	return (
		<div className='AuthorLayout'>
			<Img
				className='imageBg'
				alt={tr(
					`${
						fix ? section : lower(section)
					}_section.alts.image1`
				)}
				src={`${basePicturesUrl}/jpg/${
					fix ? capitalize(section) : section
				}SectionBg${mode}.jpg`}
			/>
			<Img
				className='avatar'
				alt={tr(
					`${
						fix ? section : lower(section)
					}_section.alts.avatar`
				)}
				src={`${basePicturesUrl}/jpg/${
					fix ? capitalize(section) : section
				}SectionAvatar${mode}.jpg`}
			/>
			<p className='second_paragraph'>
				{tr(
					`${
						fix ? section : lower(section)
					}_section.second_paragraph`
				)}
			</p>
			{!quotes ? (
				<p className='third_paragraph'>
					{tr(
						`${
							fix ? section : lower(section)
						}_section.third_paragraph`
					)}
				</p>
			) : (
				<Quotes
					pageName={pageName}
					section={section}
					paragraph={'third'}
					top={!!top}
				/>
			)}
		</div>
	)
}

export default withTranslation()(AuthorLayout);
