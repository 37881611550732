import React, {useState} from 'react';
import s from './Slider.module.scss';
import Img from "../../../Img/Img";
import {wrap} from "popmotion";
import {useTranslation} from "react-i18next";

export default function Slider(props) {
    const [isLoad, setIsLoad] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const mode = props.mode || 'desktop';
    const slide = props.slide;
    const slides = props.slides;
    const key = props.index;
    const {t} = useTranslation();
    const slideAlts = props.slideAlts;

    const onLoad = () => {
        setIsLoad(true);
    }

    const onInit = () => {
        setIsInit(true);
        props.onInit();
    }

    return (
        <div className={`${s.imgWrapper}`}>
            <Img src = {slide} onLoad = {onInit} fullScreenMode className={s.slideImage}/>
        </div>
    );
}
