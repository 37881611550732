import {
	useEffect,
	useState,
	useRef,
} from 'react'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames/bind'
import s from './NextHeader.module.scss'
import {
	ArrowRight,
	AntropogeosLogoHeader as Logo,
	MenuClosedIcon,
	MenuOpenIcon,
} from './icons'
import { useLocation } from 'react-router-dom'
import {
	motion,
	AnimateSharedLayout,
	AnimatePresence,
} from 'framer-motion'

import locale from 'locales/ru/translation'
import { Link } from '../Link'
import { useWindowMode } from '../../utils/useWindowMode';
// console.log(
// 	'%c 👩‍🎨: locale ',
// 	'font-size:16px;background-color:#fbdc2a;color:black;',
// 	Object.keys(locale.header.dropdown.items)
// )

const cx = classNames.bind(s)
const motionProps = ({ id, visible }) => ({
	initial: { opacity: 0 },
	animate: {
		// duration: 2 + (0.5 * id),
		x: visible ? 0 : 1250,
		opacity: visible ? 1 : 0,
	},
	exit: { opacity: 0 },
	transition: {
		type: 'tween',
		duration: 0.09 * id,
	},
})

export const NextHeader = withTranslation()(
	(
		{
			i18n,
			t,
			// parentData: {
			// 	// paginationData: { currentIdx, items },
			// },
			...restProps
		},
		...rest
	) => {
		const mode = useWindowMode()
		const [headerOpen, setHeaderOpen] =
			useState(false)

		const [menu1opened, Setmenu1opened] =
			useState(false)
		const [menu2opened, Setmenu2opened] =
			useState(false)

		const menus = {
			menu1: {
				state: menu1opened,
				setState: Setmenu1opened,
			},
			menu2: {
				state: menu2opened,
				setState: Setmenu2opened,
			},
		}
		// console.log(Object.keys(menus))
		const toggle =
			({ menuLabel, value }) =>
			e => {
				e.stopPropagation()
				// console.log(
				// 	'%c 🔞: { menuLabel, value } ',
				// 	'font-size:16px;background-color:#236821;color:white;',
				// 	{ menuLabel, value }
				// )
				menus[menuLabel].setState(value)
				Object.keys(menus).map(
					key =>
						key !== menuLabel &&
						menus[key].setState(false)
				)
			}

		const headerRef = useRef()
		useEffect(() => {
			// setHeaderOpen(false)
			// if (mode === 'desktop') {
			Setmenu1opened(false)
			Setmenu2opened(false)
			// }
		}, [mode])
		useEffect(_ => {
			window.addEventListener(
				'click',
				OutSideHandler
			)
			return _ => {
				window.removeEventListener(
					'click',
					OutSideHandler
				)
			}
		}, [])
		const OutSideHandler = e => {
			// setHeaderOpen(false)
			if (!headerRef.current.contains(e.target)) {
				Object.keys(menus).map(key =>
					menus[key].setState(false)
				)
			}
		}
		// const location = useLocation()
		const contentDropdownMenuItemNames =
			Object.keys(
				locale.app.navigation.names
			).map(key => `app.navigation.names.${key}`)
		// const contentDropdownMenuItemNames = [
		// 	'header.dropdown.items.introduction',
		// 	'header.dropdown.items.desert_space',
		// 	'header.dropdown.items.time_and_timelessness',
		// 	'header.dropdown.items.sacred_and_profane',
		// 	'header.dropdown.items.home_and_family',
		// 	'header.dropdown.items.technology_and_tradition',
		// 	'header.dropdown.items.death_and_life',
		// 	'header.dropdown.items.oasis_after_the_desert',
		// ]
		const { language, changeLanguage } = i18n
		const onlineProjectsDropdownMenuItemNames = [
			'header.dropdown.names.online_projects.gobi_desert',
			'header.dropdown.names.online_projects.antarctica',
			// 'header.dropdown.items.highlanders',
		]
		// const pageName =
		// 	!window.location.href.includes(
		// 		window.location.origin + '/#/en/'
		// 	) &&
		// 	!window.location.href.includes(
		// 		window.location.origin + '/#/ru/'
		// 	)
		// 		? window.location.href
		// 				.replace('/', '')
		// 				.replaceAll('-', '_')
		// 		: window.location.href
		// 				.split('/')
		// 				.splice(
		// 					5,
		// 					window.location.href.length
		// 				)
		// 				.join('')
		// 				.replaceAll('-', '_')

		const pageName =
			window.location.hash
				.replaceAll('#/', '')
				.replaceAll('-', '_')
				.replaceAll('en/', '')
				.replaceAll('ru/', '') || 'introduction'

		const projectsSwitch = () => (
			<>
				<button
					className={s.projectsSwitch}
					onClick={toggle({
						menuLabel: 'menu2',
						value: !menus.menu2.state,
					})}
				>
					{t(
						'header.dropdown.names.online_projects.name'
					).toUpperCase()}
					{/* {menu2opened ? (
						<ArrowTopIcon />
					) : (
						<ArrowDownIcon />
					)} */}
					<div
						style={{
							transform : `rotate(${menu2opened ? 0 : 180}deg)`,
							height: 24
						}}
					>
						<svg
							// layout
							// initial={false}
							// initial={{rotate:180}}
							// transition={{ duration: 1 }}

							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								// layout
								// animate={{
								// 	rotate: menu2opened ? 0 : 180,
								// 	// x: menu1opened ? 0 : 24,
								// 	y: menu2opened ? 0 : 24,
								// }}
								d='M17 14L12 9L7 14L17 14Z'
								fill='black'
								fillOpacity='0.9'
							/>
						</svg>
					</div>
				</button>
				<ul className={s.projects}>
					{onlineProjectsDropdownMenuItemNames.map(
						(item, key) => (
							<li
								// layout
								key={key}
								// key={key}
								// {...motionProps({
								// 	id: key + 2,
								// 	visible: menu2opened,
								// })}
							>
								<a
									key={'projects' + key}
									className={cx({
										selected: key,
									})}
									target={`${
										!key ? `_blank` : ``
									}`}
									href={`${
										!key
											? `http://gobi.anthropogeos.com/#/${language}/introduction`
											: `${
													window.location.origin
											  }/#/${language}/${pageName.replaceAll(
													'_',
													'-'
											  )}`
									}`}
								>
									{t(item)}
								</a>{' '}
							</li>
						)
					)}
				</ul>
			</>
		)
		const languageSwitch = () => (
			<a
				className={s.language}
				onClick={_ => {
					// _.preventDefault()
					// Setmenu1opened(false)
					// Setmenu2opened(false)
				}}
				href={`${window.location.origin}/#/${
					language === 'ru' ? 'en' : 'ru'
				}/${pageName.replaceAll('_', '-')}`}
			>
				{language === 'ru' ? 'ENG' : 'RU'}
				<ArrowRight />
			</a>
		)

		const contentSwitch = () => (
			<>
				<button
					className={s.contentSwitch}
					onClick={toggle({
						menuLabel: 'menu1',
						value: !menus.menu1.state,
					})}
				>
					{t(
						'header.dropdown.names.content'
					).toUpperCase()}
					<div
						style={{
							transform : `rotate(${menu1opened ? 0 : 180}deg)`,
							height: 24
						}}
					>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M17 14L12 9L7 14L17 14Z'
								fill='black'
								fillOpacity='0.9'
							/>
						</svg>
					</div>
					{/* {menu1opened ? (
						<ArrowTopIcon />
					) : (
						<ArrowDownIcon />
					)} */}
				</button>
				{/* <AnimatePresence> */}
				<ul
					// layout
					className={s.content}
					// transition={{ duration: 0.2 }}

					// initial={{ scaleY: 0 }}
					// animate={{
					// 	scaleY: menu1opened ? 1 : 0,
					// }}
				>
					{contentDropdownMenuItemNames.map(
						(item, key) => {
							// console.log(
							// 	'%c 🚮: pageName ',
							// 	'font-size:16px;background-color:#39f418;color:black;',
							// 	item
							// 		.replaceAll('header.dropdown.items.', '')
							// 		.replaceAll('_', '-') === pageName
							// )

							return (
								<li
									// layout
									// animate={
									// 	menu1opened ? 'animate' : ''
									// }
									key={key}
									// {...motionProps({
									// 	id: key,
									// 	visible: menu1opened,
									// })}
								>
									<Link>
										<a
											key={'content_' + key}
											className={cx({
												selected:
													item.replaceAll(
														'app.navigation.names.',
														''
													) === pageName,
											})}
											onClick={_ => {
												Setmenu1opened(false)
												Setmenu2opened(false)
												setHeaderOpen(false)
											}}
											href={`${
												window.location.origin
											}/#/${language}/${item
												.replaceAll(
													'app.navigation.names.',
													''
												)
												.replaceAll('_', '-')}`}
										>
											{t(item)}
										</a>
									</Link>
								</li>
							)
						}
					)}
				</ul>
				{/* </AnimatePresence> */}
			</>
		)
		return (
			<AnimateSharedLayout
				exitBeforeEnter
				type='crossfade'
			>
				<div
					className={cx({
						header: true,
						projectsOpen: menu2opened,
						contentOpen: menu1opened,
						cols: true,
						headerOpen,
					})}
					ref={headerRef}
				>
					<Link>
						<a
							target='_blank'
							href={`http://anthropogeos.com/#/${language}/`}
							// href={`/#/${language}/`}
							className={s.logo}
						>
							<Logo
								alt={t(
									'seo.Common.HeaderLogo.alt'
								)}
							/>
						</a>
					</Link>
					{contentSwitch()}
					<p className={s.title}>
						{mode !== 'mobile'? <>
							<span key={'first_part'}>
								{t(
									'header.project_title'
								).toUpperCase()}
							</span>
							<span
								key={'second_part'}
								transition={{
									// duration: 3,
									type: 'spring',
									stiffness: 500,
									damping: 50,
								}}
							>
								{t(`app.navigation.names.${pageName}`)}
							</span>
						</>
						:
						<>
							<span key={'first_part'}>
								{t(
									'app.navigation.chapters.project_title'
								).toUpperCase()}
							</span>
							<span
								key={'second_part'}
								transition={{
									// duration: 3,
									type: 'spring',
									stiffness: 500,
									damping: 50,
								}}
							>
								{t(`app.navigation.chapters.${pageName}`)}
							</span>

						</>}
					</p>
					{mode === 'desktop' ? (
						<div className={s.actions}>
							{projectsSwitch()}
							{languageSwitch()}
						</div>
					) : (
						<>
							<div className={s.divider1}></div>
							{projectsSwitch()}
							<div className={s.divider2}></div>
							{languageSwitch()}
							<div className={s.divider3}></div>
						</>
					)}
					<button
						className={s.menuSwitch}
						onClick={a => {
							setHeaderOpen(!headerOpen)
							Setmenu1opened(false)
							Setmenu2opened(false)
						}}
					>
						<svg
							width='50'
							height='39'
							viewBox='0 0 50 39'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<motion.rect
								y='18'
								width='50'
								height='3'
								fill='black'
								fillOpacity='0.9'
								transition={{
									duration: 0.2,
									type: 'spring',
									stiffness: 300,
									damping: 20,
								}}
								animate={{
									opacity: headerOpen ? 0 : 1,
								}}
							/>
							<motion.rect
								// y='16'
								width='50'
								height='3'
								fill='black'
								fillOpacity='0.9'
								transition={{
									duration: 0.2,
									type: 'spring',
									stiffness: 300,
									damping: 20,
								}}
								// initial={{y:16}}
								animate={{
									rotate: headerOpen ? -45 : 0,
									y: headerOpen ? 18 : 25,
								}}
							/>
							<motion.rect
								width='50'
								height='3'
								fill='black'
								fillOpacity='0.9'
								transition={{
									duration: 0.2,
									type: 'spring',
									stiffness: 300,
									damping: 20,
								}}
								animate={{
									rotate: headerOpen ? 45 : 0,
									y: headerOpen ? 18 : 11,
								}}
							/>
							{/* </g> */}
						</svg>
					</button>
				</div>
			</AnimateSharedLayout>
		)
	}
)
