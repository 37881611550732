import { useTranslation } from 'react-i18next'
import s from './parts.module.scss'

export const RightArrow = ({
	onClick,
	className,
	...rest
}) => {
	return (
		<div
			className={`${className} ${s.arrow} ${s.right}`}
			onClick={() => onClick()}
		>
			<div className={`${s.itemDiv} ${s.rightArr}`}></div>
		</div>
	)
}

export const LeftArrow = ({
	onClick,
	className,
	...rest
}) => {
	return (
		<div
			className={`${className} ${s.arrow} ${s.left}`}
			onClick={() => onClick()}
		>
			<div className={`${s.itemDiv} ${s.leftArr}`}></div>
		</div>
	)
}
export const Pull = ({ isVisible, hide }) => {
	const { t } = useTranslation()

	return (
		<button
			className={`${s.pull} ${
				isVisible ? s.animationPull : ''
			} ${hide ? s.animationHide : ''}`}
		>
			{t('app.buttons.swipe')}
		</button>
	)
}

export const Dot = ({
	onClick,
	active,
	...rest
}) => {
	return (
		<button
	className={`${s.dot} ${
		active ? s.active : ''
	}`}
	onClick={() => onClick()}
	/>
	)
}

export const Linear = ({
	currentPart,
	partsCount,
	...rest
}) => {
	let progress = Math.round((currentPart/partsCount)*100);
	return(
		<div className={s.linear}>
			<div className={s.linearBack}>
				<div className={s.linearFront} style={{width: `${progress}%`}}></div>
			</div>
		</div>
	)
}

export const Dots = ({currentPart, partsCount, click, ...rest}) => {
	return(
		<>
		<div className={s.dotsWrapper}>
			{Array.from(Array(partsCount)).map((x, index, key) => {
				return <button key={index+1}  className={`${s.dot} ${index <= currentPart - 1 ? s.active : ''}`} onClick={() => click(index)} />
			})}
		</div>
		</>
	)
}
