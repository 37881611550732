import { ProducePaths } from '../../../utils/pathGen'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Img from 'components/Img/Img'
import {LeftArrow, RightArrow, Pull} from '../parts'
import {
	useRef,
	useState,
	useEffect,
} from 'react'
import s from './switcher.module.scss'
import { wrap } from 'popmotion'
import { useTranslation } from 'react-i18next'
import { useIntersection } from 'react-use'
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		// partialVisibilityGutter: 156,
		items: 1,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1200 },
		items: 1,
		partialVisibilityGutter: 0,
	},
	tablet: {
		breakpoint: { max: 1199, min: 768 },
		partialVisibilityGutter: 0,
		items: 1,
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		partialVisibilityGutter: 0,
		items: 1,
	},
}

export const SwitcherTab = ({
	page,
	section,
	slides,
	className,
	whiteArrows,
	showPull,
	styleSliders = {}
}) => {
	const instance = useRef(null)
	const [pullHidden, setPullHidden] =
		useState(false)
	const [currentSlide, setCurrentSlide] =
		useState(1)
	const { t } = useTranslation()
	const {
		slideNames,
		imageAlts,
		imageDescriptions,
	} = ProducePaths({
		page,
		section,
		slides,
	})
	const ButtonGroup = ({
			 next,
			 previous,
			 goToSlide,
			 ...rest
		 }) => (
			<>
				<LeftArrow
					className={`${s.arrow} ${s.left} ${whiteArrows && !slim ? s.white : whiteArrows && slim ? s.black : whiteArrows ? s.white : s.black}`}
					onClick={() => previous()}
				/>
				<RightArrow
					className={`${s.arrow} ${s.right} ${whiteArrows && !slim ? s.white : whiteArrows && slim ? s.black : whiteArrows ? s.white : s.black}`}
					onClick={() => next()}
				/>
			</>
		)
	const wrapperRef = useRef(null)
	const [isVisible, setVisible] = useState(false)
	const intersection = useIntersection(
		wrapperRef,
		{
			threshold: 1,
		}
	)
	useEffect(() => {
		if (intersection?.isIntersecting === true)
			setVisible(true)
	}, [intersection?.isIntersecting])

	const [slim, setSlim] = useState(false)

	useEffect(() => {
		let image = document.querySelector(`img.${section}visibleImage`);
		if (image) {
			let widthImg = image.onLoad = () => {
				let width = image.naturalWidth
				let height = image.naturalHeight
				if (width && width < height) {
					setSlim(true)
				} else {
					setSlim(false)
				}
			}
			widthImg()
		}
	}, [currentSlide])

	const Miniature = data => {
		return (
			<Img
				src={
					slideNames[
						wrap(0, slides, data.index + 1)
					]
				}
				className={
					data.active ? s.visible : s.miniature
				}
				onClick={() => {
					instance.current.next()
				}}
			/>
		)
	}
	return (
		<div
			ref={wrapperRef}
			className={`${className} ${s.tabSwitcher1}`}
		>
			{/* <div className={s.controls}> */}

			{ showPull ? (
				<Pull
					isVisible={isVisible}
					hide={pullHidden}
				/>
			) : null
			}

			<p
				className={
					t(
						imageAlts[
							wrap(1, slides + 1, currentSlide) -
								1
						]?.replace('.alt', '.counter')
					) === 'black'
						? s.black
						: ''
				}
				style={
					t(
						imageAlts[
							wrap(1, slides + 1, currentSlide) -
								1
						]?.replace('.alt', '.counter')
					) === 'black'
						? { color: '#566C91' }
						: {}
				}
			>{`${wrap(
				1,
				slides + 1,
				currentSlide
			)}/${slides}`}</p>
			{/* </div> */}

			<Carousel
				ref={instance}
				renderDotsOutside
				showDots
				dotListClass={s.miniatures}
				customButtonGroup={
					<ButtonGroup />
				}
				customDot={<Miniature />}
				className={s.slider}
				swipeable
				afterChange={(slide, state) => {
					// console.log(
					// 	'%c 🕵️‍♂️: slide,a,b ',
					// 	'font-size:16px;background-color:#bf3e5d;color:white;',
					// 	state
					// )
					setCurrentSlide(
						wrap(
							1,
							slides + 1,
							state.currentSlide -1
						)
					)
					setPullHidden(true)
				}}
				arrows={false}
				infinite
				responsive={responsive}
			>
				{slideNames.map((slide, key) => {
					return (
						<Img
							src={slide}
							key={key}
							alt={imageAlts[key]}
							style={styleSliders}
							className={slim && currentSlide - 1 === key ? `${s.slimImage} ${section}visibleImage` : currentSlide - 1 === key ? `${section}visibleImage` : ''}
							// style={{maxHeight: `${(window.innerWidth-80)/1.456}px`}}
						/>
					)
				})}
			</Carousel>

			<em className={slim ? `${s.slim}` : ''}>
				{t(
					imageDescriptions[
						wrap(0, slides, currentSlide - 1)
					]
				)}
			</em>
		</div>
	)
}
