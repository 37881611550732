import { useTranslation, withTranslation } from "react-i18next";

import { useWindowMode } from "utils/useWindowMode";

import { usePictureMode } from "../../utils";
import Img from "../../components/Img/Img";

import "./Introduction.scss";
import { usePageName } from "utils";
import { Slider1 } from "components/Sliders/Slider1";
import { Switcher as DeskSwitcher } from "components/Sliders/Desk/Switcher";
import { SwitcherTab as TabletSwitcher } from "components/Sliders/Tab/SwitcherTab";
import { Divider } from "../../components/Divider";
import { ProducePaths } from "utils/pathGen";
import { getLanguage } from "utils/customLanguageDetector";
import { Player } from "../../components/VideoJS/Player";
import FadeInSection from "../../components/FadeInOut/FadeSection";
import DesktopSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import TabletSlider from "../../components/Sliders/NewSliders/Tablet/Tablet";
import MobileSlider from "../../components/Sliders/NewSliders/Mobile";

const Members = ({ page, section, items }) => {
	const {members, imageDescriptions, imageAlts} = ProducePaths({
		page,
		section,
		slides: items,
	});
	const {t} = useTranslation();
	const size = useWindowMode();

	return members.map((member, key) => {
			const styleImg = () => {
				if (key === 0) return size === 'tablet' ? {maxWidth: '348px'} : {maxWidth: '254px'};
				else return {maxWidth: '254px'};
			};
			return (<li key={key}>
				<figure>
					<Img src={member} alt={t(imageAlts[key])} style={styleImg()}/>
					<figcaption>{t(imageDescriptions[key])}</figcaption>
				</figure>
			</li>)
		}
	);
}

const Introduction = ({t}) => {
  const pageName = usePageName();
  const size = useWindowMode();
  const tr = (slice) => {
    return t(`pages.${pageName}.${slice}`);
  };

  const mode = usePictureMode();
  const basePicturesUrl = `/images/` + pageName;

	const secondSectionConfig = {
		className: "slider",
		page: pageName,
		section: "second",
		slides: 3,
	};

  const fifthSliderConfig = {
    className: "slider",
    page: pageName,
    section: "fifth",
    slides: 4,
  };
  const sixthSliderConfig = {
    className: "slider",
    page: pageName,
    section: "sixth",
    slides: 3,
  };

  return (
		<main className={pageName}>
			<FadeInSection>
				<section className='pagePreview'>
					<Img
						className='image'
						src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
						alt={tr('first_section.alts.image1')}
					/>
					<h1 className='title'>{tr('first_section.title')}</h1>
					<h2 className='content'>{tr('first_section.content')}</h2>
				</section>
				<section className='second_section'>
					<h3>{tr('second_section.title')}</h3>
					<p>{tr('second_section.paragraph')}</p>
					{size === 'desktop' ? (
						<DesktopSlider {...secondSectionConfig} id={'second_section_slider'}  borderSliders={3}/>
					) : size === 'tablet' ? (
						<TabletSlider
							{...secondSectionConfig}
							id={"second_section_slider"}
							borderSliders={3}
							fromStart
							isLinear
							whiteArrows
						/>
					) : (
						<MobileSlider
							{...secondSectionConfig}
							id={"second_section_slider"}
							borderSliders={3}
							isLinear
							hidePull
							isArrows
						/>
					)}
					{/*<DesktopSlider {...secondSectionConfig} id={'second_section_slider'}  borderSliders={3}/>*/}
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='third_section'>
					<p>{tr(`third_section.first_paragraph`)}</p>

					<p>{tr(`third_section.second_paragraph`)}</p>

					<figure>
						<Img
							className='image'
							src={`${basePicturesUrl}/jpg/ThirdSectionBg${mode}.jpg`}
							alt={tr(`third_section.alts.image1`)}
							fullScreenMode
						/>
						<figcaption>{tr(`third_section.description`)}</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='fourth_section'>
					<p>{tr(`fourth_section.first_paragraph`)}</p>

					<figure>
						<Img
							className='image'
							src={`${basePicturesUrl}/jpg/FourthSectionBg${mode}.jpg`}
							alt={tr(`fourth_section.alts.image1`)}
							fullScreenMode
						/>
						<figcaption>{tr(`fourth_section.description`)}</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<Divider d={140} t={100} m={80} />
			<FadeInSection>
				<section className='fifth'>
					<h3>{tr(`fifth_section.title`)}</h3>
					<p>{tr(`fifth_section.first_paragraph`)}</p>
					{size === 'desktop' ? (
						<DeskSwitcher {...fifthSliderConfig} />
					) : size === 'tablet' ? (
						<TabletSwitcher {...fifthSliderConfig} whiteArrows />
					) : (
						<MobileSlider {...fifthSliderConfig} id={"fifth_section_slider"} borderSliders={3} />
					)}
				</section>
			</FadeInSection>
			<Divider d={126} t={80} m={80} />
			<FadeInSection>
				<section className='sixth'>
					<h3>{tr(`sixth_section.title`)}</h3>
					{/*<Slider1*/}
					{/*	{...sixthSliderConfig}*/}
					{/*	noDots*/}
					{/*	whiteArrows={size !== "desktop"}*/}
					{/*	showLinear={size !== "desktop"}*/}
					{/*	fromStart={size === "tablet"}*/}
					{/*	noPull={size === 'mobile'}*/}
					{/*	partial={size === "tablet"}*/}
					{/*/>*/}
					{size === 'desktop' ? (
						<DesktopSlider {...sixthSliderConfig} id={'six_section_slider'}  borderSliders={3}/>
					) : size === 'tablet' ? (
						<TabletSlider {...sixthSliderConfig} id={"six_section_slider"} borderSliders={2} isLinear whiteArrows/>
					) : (
						<MobileSlider {...sixthSliderConfig} id={"six_section_slider"} borderSliders={3} isLinear
									  hidePull
									  isArrows/>
					)}
					{/*<DesktopSlider {...sixthSliderConfig} id={'six_section_slider'}  borderSliders={2}/>*/}
				</section>
				<Divider d={140} t={100} m={80} />
				<section className='seventh'>
					<h3>{tr(`seventh_section.title`)}</h3>
					<p>{tr(`seventh_section.first_paragraph`)}</p>
					<ul>
						<Members page={pageName} section={'seventh'} items={5} />
					</ul>
				</section>
			</FadeInSection>
			<Divider d={140} t={80} m={80} />
			<FadeInSection>
				<section className={`section_with_video `}>
					<Player name={'1.Expedition'} page={pageName} section={'eighth'} />
				</section>
			</FadeInSection>
			<Divider d={140} t={80} m={80} />
			<FadeInSection>
				<section className='ninth'>
					<p className='first'>{tr(`ninth_section.first_paragraph`)}</p>
					<figure>
						<Img
							className='image'
							src={`${basePicturesUrl}/jpg/NinthSectionMap_${getLanguage()}${mode}.jpg`}
							alt={tr(`ninth_section.alts.image1`)}
						/>
						<figcaption>{tr(`ninth_section.description`)}</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<Divider d={70} t={80} m={80} />
			<Divider d='0' t='0' m='60' />
		</main>
	)
};

export default withTranslation()(Introduction);
