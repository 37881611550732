import s from './FuelDepotFd83.module.scss'
import {
	usePageName,
	useWindowMode,
	usePictureMode,
} from 'utils'
import Img from 'components/Img/Img'
import { useTranslation } from 'react-i18next'
import SwitchTwoImages from 'components/SwitchTwoImages/SwitchTwoImages'
import { Slider1 } from 'components/Sliders/Slider1'
import { Switcher as DeskSwitcher } from 'components/Sliders/Desk/Switcher'
import { Switcher as DeskSwitcher2 } from 'components/Sliders/Desk/Switcher'
import { SwitcherTab as TabletSwitcher } from 'components/Sliders/Tab/SwitcherTab'
import AuthorLayout from 'components/AuthorLayout/AuthorLayout'
import Quotes from 'components/Quotes/Quotes'
// import { Divider } from 'components/Divider'
import { Player } from 'components/VideoJS/Player'
import FadeInSection from 'components/FadeInOut/FadeSection'
import CustomSliderMobile from "../../components/Sliders/NewSliders/Mobile";
import TabletSlider from "../../components/Sliders/NewSliders/Tablet/Tablet";

const FuelDepotFd83 = () => {
	const { t } = useTranslation()
	const pageName = usePageName()
	const size = useWindowMode()
	const tr = slice => {
		return t(`pages.${pageName}.${slice}`)
	}

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName
	const fifthSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'fourth',
		slides: 5,
	}
	return (
		<main className={` ${s.page} `}>
			<FadeInSection>

			<section className={s.pagePreview}>
				<Img
					className={s.image}
					src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
					alt={tr('first_section.alts.image1')}
				/>
				<h1 className={s.title}>
					{tr('first_section.title')}
				</h1>
				<div className={s.coordinates}>
					<img
						src='/icons/LOCATION_ICON.svg'
						alt='LOCATION ICON'
					/>
					<h2>
						{tr('first_section.coordinates')}
					</h2>
				</div>
				<div className={s.height}>
					<img
						src='/icons/altitude_icon.svg'
						alt='altitude ICON'
					/>
					<h2>{tr('first_section.height')}</h2>
				</div>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.first} `}>
				<h3>{tr('first_section2.title')}</h3>
				<p>
					{tr('first_section2.first_paragraph')}
				</p>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FirstSectionImage1${mode}.jpg`}
						alt={tr('first_section2.alts.image1')}
						fullScreenMode
					/>
					<figcaption>
						{tr('first_section2.description')}
					</figcaption>
				</figure>

				<h4>{tr('first_section2.heading')}</h4>
				<p>
					{tr('first_section2.second_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.second} `}>
				<SwitchTwoImages
					pageName={pageName}
					section={'second'}
					fix
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.third} `}>
				<h4>{tr('third_section.heading')}</h4>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/ThirdSectionImage1${mode}.jpg`}
						alt={tr('third_section.alts.image1')}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/ThirdSectionImage2${mode}.jpg`}
						alt={tr('third_section.alts.image2')}
						fullScreenMode
					/>
					<figcaption>
						{tr('third_section.description')}
					</figcaption>
				</figure>
				<p>
					{tr('third_section.first_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.fourth} `}>
				{size === 'desktop' ? (
					<DeskSwitcher2
						{...fifthSliderConfig}
						fiveImages
					/>
				) : size === 'tablet' ? (
					<TabletSwitcher {...fifthSliderConfig} whiteArrows/>
				) : (
					// <TabletSwitcher
					// 	{...fifthSliderConfig}
					// />
					<CustomSliderMobile {...fifthSliderConfig} id={"fifth_section_slider"} borderSliders={3} isDots/>
				)}
				<h4>{tr('fourth_section.heading')}</h4>
				<p>
					{tr('fourth_section.first_paragraph')}
				</p>
				{/* </section>
			<section className={` ${s.fifth} `}> */}
				<SwitchTwoImages
					pageName={pageName}
					section={'fifth'}
					fix
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.sixth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'sixth'}
					fix
				/>
				<Quotes
					top
					pageName={pageName}
					section={'sixth'}
					paragraph={'first'}
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.seventh} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/SeventhSectionImage1${mode}.jpg`}
						alt={tr(
							'seventh_section.alts.image1'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('seventh_section.description')}
					</figcaption>
				</figure>
				<Quotes
					bottom
					pageName={pageName}
					section={'seventh'}
					paragraph={'first'}
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.eighth} `}>
				<h4>{tr('eighth_section.title')}</h4>
				<p>
					{tr('eighth_section.first_paragraph')}
				</p>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/EighthSectionImage1${mode}.jpg`}
						alt={tr('eighth_section.alts.image1')}
						fullScreenMode
					/>
					<figcaption>
						{tr('eighth_section.description')}
					</figcaption>
				</figure>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.ninth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'ninth'}
					fix
				/>
				<Quotes
					top
					pageName={pageName}
					section={'ninth'}
					paragraph={'fourth'}
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.tenth} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TenthSectionImage1${mode}.jpg`}
						alt={tr('tenth_section.alts.image1')}
						fullScreenMode
					/>
					<figcaption>
						{tr('tenth_section.description')}
					</figcaption>
				</figure>
				<Quotes
					bottom
					pageName={pageName}
					section={'tenth'}
					paragraph={'first'}
				/>
				<h3>{tr('tenth_section.title')}</h3>
				<h4>{tr('tenth_section.heading')}</h4>
				<Player
					name={'8.Snow_taxiing'}
					page={pageName}
					section={'tenth'}
				/>
				<p>
					{tr('tenth_section.second_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.eleventh} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/EleventhSectionImage1${mode}.jpg`}
						alt={tr(
							'eleventh_section.alts.image1'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('eleventh_section.description')}
					</figcaption>
				</figure>
				<p>
					{tr('eleventh_section.first_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.twelfth} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwelfthSectionImage1${mode}.jpg`}
						alt={tr(
							'twelfth_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwelfthSectionImage2${mode}.jpg`}
						alt={tr(
							'twelfth_section.alts.image2'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwelfthSectionImage3${mode}.jpg`}
						alt={tr(
							'twelfth_section.alts.image3'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('twelfth_section.description')}
					</figcaption>
				</figure>
				<p>
					{tr('twelfth_section.first_paragraph')}
				</p>
				<h4>{tr('twelfth_section.heading')}</h4>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.thirteenth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'thirteenth'}
					fix
					quotes
				/>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/ThirteenthSectionImage1${mode}.jpg`}
						alt={tr(
							'thirteenth_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/ThirteenthSectionImage2${mode}.jpg`}
						alt={tr(
							'thirteenth_section.alts.image2'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('thirteenth_section.description')}
					</figcaption>
				</figure>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.fourteenth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'fourteenth'}
					fix
					quotes
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.fifteenth} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FifteenthSectionImage1${mode}.jpg`}
						alt={tr(
							'fifteenth_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FifteenthSectionImage2${mode}.jpg`}
						alt={tr(
							'fifteenth_section.alts.image2'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FifteenthSectionImage3${mode}.jpg`}
						alt={tr(
							'fifteenth_section.alts.image3'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FifteenthSectionImage4${mode}.jpg`}
						alt={tr(
							'fifteenth_section.alts.image4'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('fifteenth_section.description')}
					</figcaption>
				</figure>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.sixteenth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'sixteenth'}
					fix
					quotes
				/>
			</section> 
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.seventeenth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'seventeenth'}
					fix
					quotes
				/>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/SeventeenthSectionImage1${mode}.jpg`}
						alt={tr(
							'seventeenth_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/SeventeenthSectionImage2${mode}.jpg`}
						alt={tr(
							'seventeenth_section.alts.image2'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/SeventeenthSectionImage3${mode}.jpg`}
						alt={tr(
							'seventeenth_section.alts.image3'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr(
							'seventeenth_section.description'
						)}
					</figcaption>
				</figure>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.eighteenth} `}>
				<AuthorLayout
					pageName={pageName}
					section={'eighteenth'}
					fix
					quotes
				/>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/EighteenthSectionImage1${mode}.jpg`}
						alt={tr(
							'eighteenth_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/EighteenthSectionImage2${mode}.jpg`}
						alt={tr(
							'eighteenth_section.alts.image2'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/EighteenthSectionImage3${mode}.jpg`}
						alt={tr(
							'eighteenth_section.alts.image3'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('eighteenth_section.description')}
					</figcaption>
				</figure>
			</section>
			</FadeInSection>
			<FadeInSection>
			
			<section className={` ${s.nineteenth} `}>
				<h3>{tr('nineteenth_section.title')}</h3>
				<h4>
					{tr('nineteenth_section.heading')}
				</h4>
				<p>
					{tr(
						'nineteenth_section.first_paragraph'
					)}
				</p>
				<p>
					{tr(
						'nineteenth_section.fourth_paragraph'
					)}
				</p>
				<AuthorLayout
					pageName={pageName}
					section={'nineteenth'}
					fix
					quotes
					top
				/>
				<SwitchTwoImages
					pageName={pageName}
					section={'nineteenth'}
					fix
				/>{' '}
				<Quotes
					none
					pageName={pageName}
					section={'nineteenth'}
					paragraph={'fifth'}
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.twentieth} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwentiethSectionImage1${mode}.jpg`}
						alt={tr(
							'twentieth_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwentiethSectionImage2${mode}.jpg`}
						alt={tr(
							'twentieth_section.alts.image2'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr('twentieth_section.description')}
					</figcaption>
				</figure>{' '}
				<Quotes
					none
					pageName={pageName}
					section={'twentieth'}
					paragraph={'first'}
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.twentyfirst} `}>
				<figure>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwentyfirstSectionImage1${mode}.jpg`}
						alt={tr(
							'twentyfirst_section.alts.image1'
						)}
						fullScreenMode
					/>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwentyfirstSectionImage2${mode}.jpg`}
						alt={tr(
							'twentyfirst_section.alts.image2'
						)}
						fullScreenMode
					/>{' '}
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/TwentyfirstSectionImage3${mode}.jpg`}
						alt={tr(
							'twentyfirst_section.alts.image2'
						)}
						fullScreenMode
					/>
					<figcaption>
						{tr(
							'twentyfirst_section.description'
						)}
					</figcaption>
				</figure>
				<Quotes
					none
					pageName={pageName}
					section={'twentyfirst'}
					paragraph={'first'}
				/>
			</section>
			</FadeInSection>
			<FadeInSection>

			<section className={` ${s.twentysecond} `}>
				<SwitchTwoImages
					pageName={pageName}
					section={'twentysecond'}
					fix
				/>
				<Quotes
					bottom
					pageName={pageName}
					section={'twentysecond'}
					paragraph={'first'}
				/>
				<Player
					name={'5.Cars'}
					page={pageName}
					section={'twentysecond'}
				/>
				<h4>
					{tr('twentysecond_section.heading')}
				</h4>
				<p>
					{tr(
						'twentysecond_section.second_paragraph'
					)}
				</p>
			</section>
			</FadeInSection>
		</main>
	)
}

export default FuelDepotFd83
