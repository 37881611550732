import React, {useEffect, useLayoutEffect, useState} from 'react';
import s from './Slider.module.scss';
import {ProducePaths} from "../../../../utils";
import {LeftArrow, RightArrow} from "../../parts";
import Slider from "./Slider";
import {useTranslation} from "react-i18next";
import {wrap} from "popmotion";

export default function CustomSlider(props) {
    const sleep = 500;
    const borderRepeatCount = props.borderSliders;
    const count = props.slides ? props.slides : 0;
    const id = props.id ? props.id : 'single-slider';
    const [itemIndex, setItemIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const [counterOffset, setCounterOffset] = useState(0);
    const [isAnimation, setIsAnimation] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [touchRec, setTouchRec] = useState({start: 0, move: 0});
    const [width, setWidth] = useState({start: 0, move: 0});
    const {page, section, slides, fromStart, withCounter, noDescription} = props;
    const {t} = useTranslation();
    const {
        slideNames,
        imageAlts,
        slideAlts,
        imageDescriptions,
        titleSlider,
    } = ProducePaths({
        page,
        section,
        slides,
    });
    const slidesImages = slideNames ? slideNames.slice(-borderRepeatCount).concat(slideNames).concat(slideNames.slice(0, borderRepeatCount))
        : [];

    const checkIdx = (idx = itemIndex) => {
        let _idx = idx;
        if (idx < 0) _idx = idx+count;
        if (idx >= count) _idx = idx-count;
        return _idx;
    }

    const getFullScreenSlidersData = (sliders = [], itemIndex = 0) => {
        console.log(sliders)
        const fsSliders = sliders.map((sl, key) => ({
                src: sl.replace('.jpg', 'FS.jpg').replace('/jpg/', '/jpg_new/'),
                alt: t(slideAlts[key])
            })
        );
        return JSON.stringify(fsSliders.slice(wrap(0, slides, itemIndex)).concat(fsSliders.slice(0, wrap(0, slides, itemIndex))));
    };

    useEffect(() => {
        setItemIndex(0);
        getOffset();
        const onResize = (e) => {
            const w = e.target.innerWidth;
            if (w !== width) setWidth(w);
        }
        window.addEventListener('resize', onResize);
        return () => {window.removeEventListener('resize', onResize)};
    }, []);

    useEffect(()=>{
        getOffset();
        if (!isAnimation) setItemIndex(checkIdx(itemIndex));
    }, [isAnimation]);

    useEffect(()=>{
        getOffset();
        if(!isClicked && (itemIndex < 0 || itemIndex >= count)) setIsAnimation(false);
    }, [isClicked]);

    useLayoutEffect(()=>{
        getOffset();
        setIsAnimation(false);
    }, [width])

    useLayoutEffect(() => {
        getOffset();
    }, [itemIndex]);

    const onInit = (() => {
        let initCount = 0;
        return () => {
            initCount++;
            if (initCount >= (count+borderRepeatCount*2)) getOffset();
        }
    })();

    function getOffset(idx = itemIndex) {
        const targetEl = document.querySelector(`#${id} [data-idx="${idx}"]`);
        const parentEl = document.querySelector(`#${id}`);
        if (withCounter) {
            const counterEl = document.querySelector(`#${id} .slider-counter`);
            const alignBySlide = (parentEl.offsetWidth + targetEl.offsetWidth) / 2 - counterEl.offsetWidth - 100;
            const alignByWidth = parentEl.offsetWidth - counterEl.offsetWidth - 20;
            setCounterOffset(alignBySlide<alignByWidth ? alignBySlide : alignByWidth);
        }
        const space = parseInt(window.getComputedStyle(targetEl, null).getPropertyValue("margin-right"));
        const _offset = fromStart ?
            parentEl.offsetWidth / 2 - (targetEl.offsetLeft + targetEl.offsetWidth / 2) - space * 1.5
            :
            parentEl.offsetWidth / 2 - (targetEl.offsetLeft + targetEl.offsetWidth / 2) + space;

        setOffset(_offset);
    }

    const onClick = (e, isNext) => {
        setIsAnimation(true);
        if(isClicked) return;
        let idx = isNext ? itemIndex + 1 : itemIndex - 1;
        setItemIndex(idx);
        setIsClicked(true);
        setTimeout(()=>{setIsClicked(false)}, sleep);
    }

    const onSwipe = (e) => {
        e.stopPropagation();
        const clientX = Array.from(e.targetTouches).reduce((acc, {clientX}) => {
            return acc + clientX;
        }, 0) / Array.from(e.targetTouches).length;
        const _scrollEl = document.querySelector('html');
        const _touchRec = Object.assign({}, touchRec);
        switch (e.type) {
            case 'touchstart':
                _scrollEl.style.overflow = 'hidden';
                _touchRec.start = clientX;
                break;
            case 'touchmove':
                _touchRec.move = clientX - touchRec.start;
                setIsAnimation(false);
                break;
            case 'touchend':
                _scrollEl.style.overflow = 'inherit';
                setIsAnimation(true);
                const _width = e.target.offsetWidth;
                if (Math.abs(_touchRec.move) > _width / 4) {
                    let _idx = itemIndex;
                    _idx = _touchRec.move > 0 ? onClick(null, false) : onClick(null, true);
                }
                _touchRec.start = 0;
                _touchRec.move = 0;
                break;
        }
        if (Math.abs(_touchRec.start - touchRec.start) >= 1 || Math.abs(_touchRec.move - touchRec.move) >= 1)
            setTouchRec(_touchRec);
    }

    const renderChildren = () => slidesImages.map((slide, idx) => (
        <div className={`${s.slideContainer}`} data-idx={idx - borderRepeatCount} key={idx - borderRepeatCount}>
            <Slider slide={slide} onInit = {onInit} fullScreen={idx - borderRepeatCount === itemIndex} index={idx - borderRepeatCount} current={itemIndex} slides = {slidesImages} slideAlts = {slideAlts}/>
        </div>
    ));

    return (
        <div id={id} className={`${s.sliderWrapper}`}>
            <div className={`${s.buttonContainer} ${s.left}`}>
                <LeftArrow
                    className={`arrow left`}
                    onClick={(e) => onClick(e,false)}
                />
            </div>
            <div className={`${s.sliderContainer}`} onTouchStart={onSwipe} onTouchEnd={onSwipe} onTouchMove={onSwipe} dataFullScreen={getFullScreenSlidersData(slideNames, itemIndex)}>
                {withCounter &&
                    <div className={`slider-counter ${s.sliderCounter}`} style={{transform: `translate(${counterOffset}px, 0)`}}>{checkIdx(itemIndex)+1}/{count}</div>
                }
                <div className={`${s.slideInline}`} style={{transform: `translate(${offset+touchRec.move}px, 0)`, transition: `${isAnimation ? `transform ${sleep}ms ease-out` : 'none'}`}}>
                    {renderChildren()}
                </div>
            </div>
            <div className={`${s.buttonContainer} ${s.right}`}>
                <RightArrow
                    className={`arrow right`}
                    onClick={(e) => onClick(e,true)}
                />
            </div>
            {!noDescription && (
                <em>
                    {t(
                        `pages.${page}.${section}_section.description`
                    ) ===
                    `pages.${page}.${section}_section.description`
                        ? t(imageDescriptions[itemIndex])
                        : t(
                            `pages.${page}.${section}_section.description`
                        )}
                </em>
            )}
        </div>
    );
}
