import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'
import classNames from 'classnames/bind'

import s from './Footer.module.scss'
import {
	FooterLogoIcon,
	FooterLogoIconEN,
	FacebookIcon,
	InstagramIcon,
	YoutubeIcon,
} from './icons'
import { withRouter, Route } from 'react-router-dom'
import { BottomPagination } from '../BottomPagination/BottomPagination'

class FooterComponentClass extends Component {
	constructor(props) {
		super(props)
		this.parentData = props.parentData

		this.onInnerContentItemClick =
			this.onInnerContentItemClick.bind(this)
		this.onOuterContentItemClick =
			this.onOuterContentItemClick.bind(this)
	}

	onInnerContentItemClick(event, itemName) {
		this.parentData.onInnerItemClick(
			event,
			itemName,
			'footer.titles.'
		)
	}

	onOuterContentItemClick(event, itemName) {
		this.parentData.onOuterItemClick(
			event,
			itemName,
			'footer.titles.'
		)
	}

	render() {
		const {
			t,
			mode,
			i18n: { language },
		} = this.props

		const innerContentItemNames = [
			'footer.titles.introduction',
			'footer.titles.what_is_antarctica',
			'footer.titles.trip_preparation',
			'footer.titles.glacier_union',
			'footer.titles.intermediate_airport_aircraft',
			'footer.titles.south_pole',
			'footer.titles.fuel_depot_fd83',
			'footer.titles.pole_of_not_availability_camp',
			'footer.titles.novolazarevskaya_station_shirmahers_oasis',
		]

		const outerContentItemNames = [
			{
				title: 'footer.titles.about_us',
				href: `http://anthropogeos.com/#/${language}/about`,
			},
			{
				title: 'footer.titles.online_projects',
				href: `http://anthropogeos.com/#/${language}/online_project`,
			},
			{
				title: 'footer.titles.events',
				href: `http://anthropogeos.com/#/${language}/events/past`,
			},
			{
				title: 'footer.titles.podcast',
				href: `http://anthropogeos.com/#/${language}/podcast`,
			},
		]

		const cx = classNames.bind(s)

		// const footer = cx({
		// 	footer: true,
		// })

		return (
			<>
				<BottomPagination parentData={this.parentData} mode={mode} />
				<Route path={[
					`/introduction`,
					`/${language}/introduction`,
				]}>
					<section className={s.credits}>
						<h3>{t(`footer.tenth_section.title`)}</h3>
						<ul>
							<li>
								<p>{t(`footer.tenth_section.content0`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content1`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content2`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content3`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content4`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content5`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content6`)}</p>
							</li>
							<li>
								<p>{t(`footer.tenth_section.content7`)}</p>
							</li>
						</ul>
					</section>
				</Route>
				<div
					className={cx({
						footer: true,
					})}
				>
					<a
						target='_blank'
						rel='noreferrer'
						href={`http://anthropogeos.com/#/${language}/`}
						className={s.footerLogoLink}
					>
						{mode !== 'mobile' && (
							<>
								{language === 'ru' ? (
									<FooterLogoIcon alt={t('seo.Common.FooterLogo.alt')} />
								) : (
									<FooterLogoIconEN alt={t('seo.Common.FooterLogo.alt')} />
								)}
							</>
						)}
					</a>

					<hr />
					<span className={s.copyrights}>&copy; Anthropogeos. {new Date().getFullYear()}</span>

					{mode === 'desktop' && (
						<>
							<span className={s.project_title}>{t('footer.titles.project_title')}</span>
							{map(innerContentItemNames, (itemName, i) => {
								return i % 3 === 0 ? (
									<ul key={'unordered_list' + i}>
										<li key='key33'>
											<a
												className={`${s.chapter}`}
												key={itemName}
												href={`#/${language}/${itemName.replace('footer.titles.', '').replaceAll('_', '-')}`}
												onClick={() => {
													window.scrollTo(0, 0)
												}}
											>
												{t(itemName)}
											</a>
										</li>
										<li key='key34'>
											<a
												className={`${s.chapter}`}
												href={`#/${language}/${innerContentItemNames[i + 1]
													.replace('footer.titles.', '')
													.replaceAll('_', '-')}`}
												key={innerContentItemNames[i + 1] + 'key'}
												onClick={() => {
													window.scrollTo(0, 0)
												}}
											>
												{t(innerContentItemNames[i + 1])}
											</a>
										</li>
										<li key='key35'>
											<a
												className={`${s.chapter}`}
												href={`#/${language}/${innerContentItemNames[i + 2]
													.replace('footer.titles.', '')
													.replaceAll('_', '-')}`}
												key={innerContentItemNames[i + 2]}
												onClick={() => {
													window.scrollTo(0, 0)
												}}
											>
												{t(innerContentItemNames[i + 2])}
											</a>
										</li>
									</ul>
								) : null
							})}
						</>
					)}
					{mode === 'desktop' && (
						<>
							{map(outerContentItemNames, (itemName, i) => {
								return i % 2 === 0 ? (
									<ul key={'unordered_list2' + i} className={` ${s.secondLine} `}>
										<li key='kk1'>
											<button
												onClick={_ => {
													window.open(outerContentItemNames[i].href)
												}}
											>
												{t(outerContentItemNames[i].title)}
											</button>
										</li>
										<li key='kk2'>
											<button
												onClick={_ => {
													window.open(outerContentItemNames[i + 1].href)
												}}
											>
												{t(outerContentItemNames[i + 1].title)}
											</button>
										</li>
									</ul>
								) : null
							})}
						</>
					)}

					<ul className={` ${s.secondLine} `}>
						<li key='jk1'>{t('footer.titles.contacts')}</li>
						<li key='jk2'>
							<a href='mailto:info@anthropogeos.com'>info@anthropogeos.com</a>
						</li>
					</ul>
					<ul className={` ${s.secondLine} `}>
						<li key='ok1'>{t('footer.titles.were_on_social_media')}</li>
						<li key='ok2'>
							<div className={` ${s.socials} `}>
								<a href={t('footer.titles.links_soc_media.facebook')} target='_blank' rel='noreferrer'>
									<FacebookIcon />
								</a>
								<a href={t('footer.titles.links_soc_media.instagram')} target='_blank' rel='noreferrer'>
									<InstagramIcon />
								</a>
								<a href={t('footer.titles.links_soc_media.youtube')} target='_blank' rel='noreferrer'>
									<YoutubeIcon />
								</a>
							</div>
						</li>
					</ul>
				</div>
			</>
		)
	}
}

export const Footer = withTranslation()(
	FooterComponentClass
)
