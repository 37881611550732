import { MetaTags } from "react-meta-tags";
import { NextHeader } from "./NextHeader/NextHeader";
import {
  HashRouter,
  Redirect,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";

import i18n from "i18n";
// import { getLanguage } from 'utils/customLanguageDetector'
import {useEffect, useLayoutEffect, useState} from "react";
import { Footer } from "./Footer/Footer";
import { useWindowMode, getLanguage } from "utils";

import {
	// @index('../pages/**/*.jsx', f => `${f.name},`)
	FuelDepotFd83,
	GlacierUnion,
	IntermediateAirportAircraft,
	Introduction,
	NovolazarevskayaStationShirmahersOasis,
	PoleNotAvailabilityCamp,
	SouthPole,
	TripPreparation,
	WhatIsAntarctica,
	// @endindex
} from '../pages/'
import { FullScreen } from "./FullScreen/FullScreen";
import { ShareButton } from "./ShareButton/ShareButton";
import { disableDownloadImages, disableDownloadImagesCleanup } from "../utils/disableDownloadImages";

function App({ t }) {
	const language = getLanguage()
	const location = useLocation()
	const mode = useWindowMode()
	const [fullScreenSliders, setFullScreenSliders] = useState([]);
	const [wndWidth, setWndWidth] = useState(window.innerWidth);

	useEffect(() => {
		let lng = getLanguage({
			lookupQuerystring: 'lng',
		})
		i18n.changeLanguage(lng).catch()
	}, [location])

	useEffect(() => {
		disableDownloadImages();
		return disableDownloadImagesCleanup
	}, []);

	useLayoutEffect(()=>{
		const onTotalClick = (e) => {
			const attr = 'dataFullScreen';
			const socialAttr = "dataSocial"
			const {clientX, clientY, type, target} = e;
			if((fullScreenSliders.length > 0)) {
				setFullScreenSliders([]);
				e.preventDefault();
				return;
			}
			if(target.localName==='img') {
				if (String(type).includes('menu') || String(type).includes('touch') || !target.hasAttribute(attr) && !target.parentNode.className.includes('socialBtn')) {
					e.preventDefault();
					return;
				}
			}
			if (String(type).includes('up')) {
				target.removeAttribute('clientX');
				target.removeAttribute('clientY');
				return;
			}
			if (String(type).includes('down')) {
				target.attributes['clientX'] = clientX;
				target.attributes['clientY'] = clientY;
				return;
			} else {
				const x = Number(target.attributes['clientX']) || clientX;
				const y = Number(target.attributes['clientY']) || clientY;
				if(Math.abs(x - clientX) > 4 || Math.abs(y - clientY) > 4) return;
			}
			if (!target) return;
			const children = Array.from(target.children);
			const fullScreenImg = Array.from(target.querySelectorAll(`[${attr}]`))
				.find(el=>children.includes(el));
			const el = [target, fullScreenImg].find(el => el && el.attributes && el.attributes[attr]);
			if (!el) return;
			setFullScreenSliders(JSON.parse(el.attributes[attr].value));
		}
		const onResize = () => {
			setWndWidth(window.innerWidth);
		}
		if(mode === 'desktop') {
			window.addEventListener('click', onTotalClick);
			window.addEventListener('mousedown', onTotalClick);
			window.addEventListener('mouseup', onTotalClick);
			window.addEventListener('contextmenu', onTotalClick);
			window.addEventListener('touchmove', onTotalClick, {passive: true});
		} else
			if (fullScreenSliders.length > 0) setFullScreenSliders([]);
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('click', onTotalClick);
			window.removeEventListener('mousedown', onTotalClick);
			window.removeEventListener('mouseup', onTotalClick);
			window.removeEventListener('contextmenu', onTotalClick);
			window.removeEventListener('resize', onResize);
			window.removeEventListener('touchmove', onTotalClick, {passive: true});
		}
	},[wndWidth]);

	const getPageName = () => {
		const pathNameArr = location.pathname.split('/');
		return pathNameArr[pathNameArr.length - 1].replaceAll('-','_');
	}

	return (
		<HashRouter>
			<MetaTags>
				<title>{t(`app.seo.${getPageName()}.title`)}</title>
				<meta name="description" content={t(`app.seo.${getPageName()}.description`)} />
				<meta name="keywords" content={t(`app.seo.${getPageName()}.keywords`)} />
			</MetaTags>
			{ fullScreenSliders.length && mode==='desktop' ? (
				<FullScreen sliders={fullScreenSliders} setSliders = {setFullScreenSliders} />
			) : null }
			<NextHeader />
			<div id={'content'}>
				<ShareButton/>
				<Switch>
					<Redirect
						exact
						from='/'
						to='/introduction'
					/>
					<Redirect
						exact
						from={`/${language}/`}
						to={`/${language}/introduction`}
					/>
					<Route
						path={[
							`/introduction`,
							`/${language}/introduction`,
						]}
					>
						<Introduction />
					</Route>
					<Route
						path={[
							`/what-is-antarctica`,
							`/${language}/what-is-antarctica`,
						]}
					>
						<WhatIsAntarctica />
					</Route>
					<Route
						path={[
							`/trip-preparation`,
							`/${language}/trip-preparation`,
						]}
					>
						<TripPreparation />
					</Route>
					<Route
						path={[
							`/glacier-union`,
							`/${language}/glacier-union`,
						]}
					>
						<GlacierUnion />
					</Route>
					<Route
						path={[
							`/intermediate-airport-aircraft`,
							`/${language}/intermediate-airport-aircraft`,
						]}
					>
						<IntermediateAirportAircraft />
					</Route>
					<Route
						path={[
							`/south-pole`,
							`/${language}/south-pole`,
						]}
					>
						<SouthPole />
					</Route>
					<Route
						path={[
							`/pole-of-not-availability-camp`,
							`/${language}/pole-of-not-availability-camp`,
						]}
					>
						<PoleNotAvailabilityCamp />
					</Route>
					<Route
						path={[
							`/fuel-depot-fd83`,
							`/${language}/fuel-depot-fd83`,
						]}
					>
						<FuelDepotFd83 />
					</Route>
					<Route
						path={[
							`/novolazarevskaya-station-shirmahers-oasis`,
							`/${language}/novolazarevskaya-station-shirmahers-oasis`,
						]}
					>
						<NovolazarevskayaStationShirmahersOasis />
					</Route>
				</Switch>
				<Footer mode={mode} />
			</div>
		</HashRouter>
	)
}

export default withTranslation("translation")(App);
