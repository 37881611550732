import React, {useState} from 'react';
import s from './Tablet.module.scss';
import Img from "../../../Img/Img";

export default function SliderTab(props) {
    const [isLoad, setIsLoad] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const mode = props.mode || 'tablet';
    const slide = props.slide;

    const onLoad = () => {
        setIsLoad(true);
    }

    const onInit = () => {
        setIsInit(true);
        props.onInit();
    }

    return (
        <div className={`${s.imgWrapper}`}>
            <Img src = {slide} onLoad = {onInit} fullScreenMode className={s.slideImage}/>
        </div>
    );
}
