// import { capitalize } from '../../../utils/utils'
import { usePictureMode } from '../../../utils/usePictureMode'
import Img from 'components/Img/Img'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import s from './Slider2.module.scss'
import { wrap } from 'popmotion'
import { ProducePaths } from 'utils/pathGen'
import { LeftArrow,RightArrow } from '../parts'
import { useWindowMode } from 'utils'

export const Slider2 = ({
	page,
	section,
	slides,
}) => {
	const mode = usePictureMode()
	const { t } = useTranslation()

	const [current, setCurrent] = useState(0)
	const {
		slideNames,
		imageAlts,
		imageDescriptions,
        titleSlider
	} = ProducePaths({
		page,
		section,
		slides,
	})
	const getFullScreenSlidersData = (itemIndex = 0) => {
		const fsSliders = slideNames.map((sl, key) => ({
			src: sl.replace('.jpg', 'FS.jpg'),
			alt: t(imageAlts[key])
		}));
		// todo wrap
		return JSON.stringify(fsSliders.slice(itemIndex).concat(fsSliders.slice(0, itemIndex)));
	};

    if (useWindowMode() === 'desktop')
	return (
		<>
			<div className={s.switcher}>
				<div className={s.description}>
					<p>{t(titleSlider[wrap(0, slides, current)])}</p>
					<p>{t(imageDescriptions[wrap(0, slides, current)])}</p>
				</div>
				<Img
					className={`${s.image} ${s.first}`}
					src={slideNames[wrap(0, slides, current + 2)]}
					alt={t(imageAlts[wrap(0, slides, current + 2)])}
				/>
				<Img
					className={`${s.image} ${s.second}`}
					src={slideNames[wrap(0, slides, current + 1)]}
					alt={t(imageAlts[wrap(0, slides, current + 1)])}
				/>
				<Img
					className={`${s.active} ${s.image}`}
					src={slideNames[wrap(0, slides, current)]}
					alt={t(imageAlts[wrap(0, slides, current)])}
					fullScreenMode
					dataFullScreen={getFullScreenSlidersData(current)}
				/>

				<div className={`${s.arrows}`}>
					<p className={s.counter}>{`${wrap(0, slides, current) + 1}/${slides}`}</p>
					<RightArrow onClick={() => setCurrent(current_slide => current_slide + 1)} />
					<LeftArrow onClick={() => setCurrent(current_slide => current_slide - 1)} />
				</div>
			</div>
		</>
	)
    return null
}
