import { useWindowMode } from './../utils/useWindowMode'
export const Divider = ({ m, t, d, db }) => {
	const mode = useWindowMode()
	return (
		<hr
			style={{
				border: 'none',
				background: db ? 'red' : '',
				height: `${
					mode === 'desktop'
						? d
						: mode === 'tablet'
						? t
						: m
				}px`,
			}}
		/>
	)
}
