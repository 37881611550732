import { useState } from 'react'

import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
export const getLanguage = () => {
	if (
		window.location.href.includes(
			window.location.origin + '/#/en/'
		)
	)
		return 'en'
	return 'ru'
}

export const usePageName = () => {
		const history = useHistory()

	const [path, setPath] =
		useState(

					window.location.hash
						.replaceAll('#/', '')
						.replaceAll('-', '_')
						.replaceAll('en/', '')
						.replaceAll('ru/', '') || 'introduction')
	useEffect(() => {
	const listener = 	history.listen((location, action) => {
    // console.log("%c ⌚: usePageName -> location, action ", "font-size:16px;background-color:#c90594;color:white;", location, action)
	// 	console.log('on route change')
		setPath(
			location.pathname
				// .replaceAll('#/', '')
				.replaceAll('-', '_')
				.replaceAll('/en/', '')
				.replaceAll('/ru/', '')
		)
		})
		return () => {
			listener()
		}
	}, [])
	return path
}

// export const usePageName = () => {
// 	function getName() {
// 		return (
// 			window.location.hash
// 				.replaceAll('#/', '')
// 				.replaceAll('-', '_')
// 				.replaceAll('en/', '')
// 				.replaceAll('ru/', '') || 'introduction'
// 		)
// 	}

// 	const [windowSize, setWindowSize] =
// 		useState(getName)
// 	// window.onload
// 	useEffect(() => {
// 		function handleLocationChange() {
// 			setWindowSize(getName())
//             console.log(
// 							'%c 🧟‍♂️: handleLocationChange -> getName() ',
// 							'font-size:16px;background-color:#bf7338;color:white;',
// 							getName()
// 						)
// 		}

// 		window.addEventListener(
// 			'onhashchange',
// 			handleLocationChange
// 		)
// 		return () => {
// 			window.removeEventListener(
// 				'onhashchange',
// 				handleLocationChange
// 			)
// 		}
// 	}, [])

// 	return windowSize
// }
// export const pageName =
// 			window.location.hash
// 				.replaceAll('#/', '')
// 				.replaceAll('-', '_')
// 				.replaceAll('en/', '')
// 				.replaceAll('ru/', '') || 'introduction'

export const customLanguageDetector = {
	name: 'customLanguageDetector',

	lookup(options) {
		return getLanguage(options)
	},
}
