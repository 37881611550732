import React from 'react'
import {
	useTranslation,
} from 'react-i18next'
import classNames from 'classnames/bind'
import {
	getLanguage,
	usePageName,
	useWindowMode,
} from 'utils'

import {
	PaginationArrowLeft,
	PaginationArrowRight,
	SubscribeFacebook,
	SubscribeInstagram
} from './icons'
import s from './BottomPagination.module.scss'
import { useHistory } from 'react-router-dom'

// import history from 'utils/history'

export const BottomPagination = () => {
	let history = useHistory()
	const pageName = usePageName()
	// console.log(
	// 	'%c 🌃: BottomPagination -> pageName ',
	// 	'font-size:16px;background-color:#078180;color:white;',
	// 	pageName
	// )
	const paginationData = {
		// currentIdx: 0,
		firstIdx: 0,

		items: [
			'footer.titles.introduction',
			'footer.titles.what_is_antarctica',
			'footer.titles.trip_preparation',
			'footer.titles.glacier_union',
			'footer.titles.intermediate_airport_aircraft',
			'footer.titles.south_pole',
			'footer.titles.fuel_depot_fd83',
			'footer.titles.pole_of_not_availability_camp',
			'footer.titles.novolazarevskaya_station_shirmahers_oasis',
		],
		chapters: [
			'app.navigation.chapters.introduction',
			'app.navigation.chapters.what_is_antarctica',
			'app.navigation.chapters.trip_preparation',
			'app.navigation.chapters.glacier_union',
			'app.navigation.chapters.intermediate_airport_aircraft',
			'app.navigation.chapters.south_pole',
			'app.navigation.chapters.fuel_depot_fd83',
			'app.navigation.chapters.pole_of_not_availability_camp',
			'app.navigation.chapters.novolazarevskaya_station_shirmahers_oasis',
		],
		namesTab: [
			'app.navigation.namesTab.introduction',
			'app.navigation.namesTab.what_is_antarctica',
			'app.navigation.namesTab.trip_preparation',
			'app.navigation.namesTab.glacier_union',
			'app.navigation.namesTab.intermediate_airport_aircraft',
			'app.navigation.namesTab.south_pole',
			'app.navigation.namesTab.fuel_depot_fd83',
			'app.navigation.namesTab.pole_of_not_availability_camp',
			'app.navigation.namesTab.novolazarevskaya_station_shirmahers_oasis',
		]
	}
	const currentIdx = paginationData.items.findIndex(
		item => item.replace('footer.titles.', '') === pageName.replace('/', '')
	)

	const mode = useWindowMode()
	const { t } = useTranslation()
	const onPrevButtonClick = (event, currentIdx) => {
		history.push(paginationData.items[currentIdx - 1].replaceAll('footer.titles.', '').replaceAll('_', '-'))
		window.scrollTo(0, 0)
	}
	// 	this.parentData.onInnerItemClick(
	// 		event,
	// 		this.parentData.paginationData.items[
	// 			currentIdx - 1
	// 		],
	// 		'bottom_pagination.titles.'
	// 	)
	// }

	// 	onNextButtonClick(event, currentIdx) {
	// paginationData
	// 	}

	// const onOuterItemClick = event => {
	// 	this.parentData.onOuterItemClick(event)
	// }
	const cx = classNames.bind(s)

	const onNextButtonClick = (e, data) => {
		history.push(paginationData.items[currentIdx + 1].replaceAll('footer.titles.', '').replaceAll('_', '-'))
		window.scrollTo(0, 0)
	}

	const bottom_pagination = cx({
		bottomPagination: true,
		firstPage: currentIdx === 0,
	})

	return (
		<>
			<div className={`columns ${bottom_pagination}`}>
				{currentIdx > 0 && (
					<>
						<p className={s.previousLabel}>{t('bottom_pagination.titles.go_back')}</p>
						<p
							className={s.previous}
							onClick={event => {
								onPrevButtonClick(event, currentIdx)
							}}
						>
							{mode === 'desktop' ? <PaginationArrowLeft /> : <button className={`${s.button} ${s.left}`}></button>}
							{mode === 'desktop'
								? t(paginationData.namesTab[currentIdx - 1]).toUpperCase()
								: mode === 'tablet'
									? t(paginationData.namesTab[currentIdx - 1]).toUpperCase()
									: t(paginationData.chapters[currentIdx - 1]).toUpperCase()}
						</p>
					</>
				)}
				<p className={s.currentLabel}>{t('bottom_pagination.titles.youre_here')}</p>
				<p className={s.current}>{t(paginationData.chapters[currentIdx]).toUpperCase()}</p>
				<p className={s.nextLabel}>
					{paginationData.items.length !== currentIdx
						? t('bottom_pagination.titles.continue_the_journey')
						: t('bottom_pagination.titles.next_travel')}
				</p>
				{/* <pre>
				{JSON.stringify(currentIdx, null, 2)}
			</pre>
			<pre>
				{JSON.stringify(
					paginationData.items.length,
					null,
					2
				)}
			</pre> */}
				{paginationData.items.length - 1 !== currentIdx ? (
					<p
						onClick={event => {
							onNextButtonClick(event, currentIdx)
						}}
						className={s.next}
					>
						{mode === 'desktop'
							? t(paginationData.namesTab[currentIdx + 1]).toUpperCase()
							: mode === 'tablet'
								? t(paginationData.namesTab[currentIdx + 1]).toUpperCase()
								: t(paginationData.chapters[currentIdx + 1]).toUpperCase()}
						{mode === 'desktop' ? <PaginationArrowRight /> : <button className={`${s.button} ${s.right}`}></button>}
					</p>
				) : (
					<a target='__blank' href={`https://anthropogeos.com/#/${getLanguage()}/online_project/`} className={s.next}>
						{t('app.navigation.all_exhibitions').toUpperCase()}
						{mode === 'desktop' ? <PaginationArrowRight /> : <button className={`${s.button} ${s.right}`}></button>}
					</a>
				)}
				{/* <pre>{JSON.stringify({ currentIdx, items: paginationData.items, pageName }, null, 2)}</pre> */}
			</div>
			{paginationData.items.length - 1 === currentIdx ? (
				<div className={s.bottomInTouch}>
					<p className={s.bottomInTouchLabel}>
						{t('bottom_pagination.titles.to_stay_in_take.ln0')}
						{mode === 'mobile' ? <br/> : ' '}{t('bottom_pagination.titles.to_stay_in_take.ln1')}
					</p>
					<p className={s.bottomInTouchLinks}>
						<a href={t('footer.titles.links_soc_media.facebook')} target={'_blank'}>
							<SubscribeFacebook/>
						</a>
						<a href={t('footer.titles.links_soc_media.instagram')} target={'_blank'}>
							<SubscribeInstagram/>
						</a>
					</p>
				</div>
			) : null}
		</>
	)
}
