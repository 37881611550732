import { cloneElement, Children } from 'react'

export const Link = ({ children }) => {
    const onClick = () => {
			if (children?.props?.onClick)
				children.props.onClick()

			window.scrollTo(0, 0)
		}

	return (
		<>
			{Children.map(children, child =>
				cloneElement(child, { onClick })
			)}
		</>
	)
}
