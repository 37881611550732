import {
	usePageName,
	useWindowMode,
	usePictureMode,
} from 'utils'
import Img from 'components/Img/Img'
import s from './GlacierUnion.module.scss'
import { useTranslation } from 'react-i18next'
import SwitchTwoImages from 'components/SwitchTwoImages/SwitchTwoImages'
import { Slider1 } from 'components/Sliders/Slider1'
import { Switcher as DeskSwitcher } from 'components/Sliders/Desk/Switcher'
import { SwitcherTab as TabletSwitcher } from 'components/Sliders/Tab/SwitcherTab'
import AuthorLayout from 'components/AuthorLayout/AuthorLayout'
import Quotes from 'components/Quotes/Quotes'
import { Divider } from 'components/Divider'
import FadeInSection from 'components/FadeInOut/FadeSection'
import CustomSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import DesktopSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import TabletSlider from "../../components/Sliders/NewSliders/Tablet/Tablet";
import MobileSlider from "../../components/Sliders/NewSliders/Mobile";
import {SliderVerticalHorizontal} from "../../components/Sliders/SliderVerticalHorizontal";


const GlacierUnion = () => {
	const { t } = useTranslation()
	const pageName = usePageName()
	const size = useWindowMode()
	const tr = slice => {
		return t(`pages.${pageName}.${slice}`)
	}

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName

	const fifthSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'fifth',
		slides: 9,
	}
	const sixthSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'sixth',
		slides: 3,
	}

	const eightSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'eighth',
		slides: 3,
	}

	const eleventhSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'eleventh',
		slides: 3,
	}

	const twelvethSliderConfig = {
		className: s.slider,
		page: pageName,
		section: 'twelfth',
		slides: 3,
	}
	return (
		<main className={` ${s.glacier_union} `}>
			<FadeInSection>
				<section className={s.pagePreview}>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
						alt={tr('first_section.alts.image1')}
					/>
					<h1 className={s.title}>
						{tr('first_section.title')}
					</h1>
					<div className={s.coordinates}>
						<img
							src='/icons/LOCATION_ICON.svg'
							alt='LOCATION ICON'
						/>
						<h2>
							{tr('first_section.coordinates')}
						</h2>
					</div>
					<div className={s.height}>
						<img
							src='/icons/altitude_icon.svg'
							alt='altitude ICON'
						/>
						<h2>{tr('first_section.height')}</h2>
					</div>
				</section>
			</FadeInSection>
			<Divider d='120' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.second} `}>
					<h3>{tr('second_section.title')}</h3>
					<h4>
						{tr('second_section.first_paragraph')}
					</h4>
					<SwitchTwoImages
						pageName={pageName}
						section={'second'}
					/>

					<p>
						{tr(
							'second_section.second_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>
				<section className={` ${s.third} `}>
					<figure>
						<Img
							src={`${basePicturesUrl}/jpg/ThirdSectionBg${mode}.jpg`}
							alt={tr(`third_section.image1.alt`)}
						/>
						<figcaption>
							{tr(`third_section.description`)}
						</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<Divider d='140' t='100' m='80' />
			<FadeInSection>
				<section className={` ${s.fourth} `}>
					<h3>{tr('fourth_section.title')}</h3>
					<p>
						{tr('fourth_section.first_paragraph')}
					</p>
					<p>
						{tr(
							'fourth_section.second_paragraph'
						)}
					</p>
					<figure>
						<Img
							src={`${basePicturesUrl}/jpg/FourthSectionBg${mode}.jpg`}
							alt={tr(
								`fourth_section.image1.alt`
							)}
						/>
						<figcaption>
							{tr(`fourth_section.description`)}
						</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={` ${s.fifth} `}>
					<p>
						{tr('fifth_section.first_paragraph')}
					</p>
					{size === 'desktop' ? (
						<DeskSwitcher
							{...fifthSliderConfig}
							highActive
						/>
					) : size === 'tablet' ? (
						<TabletSwitcher
							styleSliders = {{maxHeight: '500px'}}
							whiteArrows
							{...fifthSliderConfig}
						/>
					) : (
						// <Slider1 {...fifthSliderConfig} showArrows />
						<MobileSlider {...fifthSliderConfig} id={"fifth_section_slider"} borderSliders={2} />
						)}
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={` ${s.sixth} `}>
					<p>
						{tr('sixth_section.first_paragraph')}
					</p>
					{size === 'desktop' ? (
						<DesktopSlider {...sixthSliderConfig} id={"sixth_section_slider"} borderSliders={2}/>
					) : size === 'tablet' ? (
						<TabletSlider id={"sixth_section_slider"} borderSliders={2} {...sixthSliderConfig} isLinear/>
					) : (
						<MobileSlider
							{...sixthSliderConfig}
							id={"sixth_section_slider"}
							borderSliders={2}
							hidePull={true}
							isArrows={true}
							isLinear={true}
						/>
					)}
					<p>
						{tr('sixth_section.second_paragraph')}
					</p>
					<h4>{tr('sixth_section.title')}</h4>
					<p>
						{tr('sixth_section.third_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={` ${s.seventh} `}>
					<AuthorLayout
						pageName={pageName}
						section={'Seventh'}
					/>
					<Quotes
						top
						pageName={pageName}
						section={'Seventh'}
						paragraph={'fourth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='70' t='44' m='40' />
			<FadeInSection>
				<section className={` ${s.eighth} `}>
					{size === 'tablet' ?
						<TabletSwitcher
							styleSliders = {{maxHeight: '500px'}}
							whiteArrows
							{...eightSliderConfig}
						/>
						: size === "desktop" ?
						<DeskSwitcher
							{...eightSliderConfig}
						/> : <MobileSlider {...eightSliderConfig} id={"eight_section_slider"} borderSliders={2} />

					}
					<Quotes
						none
						pageName={pageName}
						section={'Eighth'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='70' t='40' m='40' />
			<FadeInSection>
				<section className={` ${s.ninth} `}>
					<Img
						src={`${basePicturesUrl}/jpg/NinthSectionImg1${mode}.jpg`}
						alt={tr(`ninth_section.image1.alt`)}
						fullScreenMode
					/>
					<Img
						src={`${basePicturesUrl}/jpg/NinthSectionImg2${mode}.jpg`}
						alt={tr(`ninth_section.image2.alt`)}
						fullScreenMode
					/>

					<Quotes
						bottom
						pageName={pageName}
						section={'ninth'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='151' t='100' m='95' />
			<FadeInSection>
				<section className={` ${s.tenth} `}>
					<AuthorLayout
						pageName={pageName}
						section={'Tenth'}
					/>
					<Quotes
						top
						pageName={pageName}
						section={'tenth'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='70' t='44' m='40' />
			<FadeInSection>
				<section className={` ${s.eleventh} `}>
					{size === 'desktop' ? (
						<SliderVerticalHorizontal {...eleventhSliderConfig} noDescription endless/>
					) : size === 'tablet' ? (
						<TabletSlider id={"eleventh_section_slider"} borderSliders={3} isLinear {...eleventhSliderConfig} whiteArrows/>
					) : (
						<MobileSlider
							{...eleventhSliderConfig}
							id={"eleventh_section_slider"}
							borderSliders={2}
							hidePull={true}
							isArrows={true}
							isLinear={true}
						/>
					)}
					{/*<CustomSlider {...eleventhSliderConfig} id={'eleventh_section_slider'}  borderSliders={2}/>*/}
					<Quotes
						none
						pageName={pageName}
						section={'eleventh'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='70' t='40' m='40' />
			<FadeInSection>
				<section className={` ${s.twelveth} `}>
					{size === 'desktop' ? (
						<DeskSwitcher
							{...twelvethSliderConfig}
						/>
					) : size === 'tablet' ? (
						<TabletSwitcher
							whiteArrows
							{...twelvethSliderConfig}
						/>
					) : (
						<MobileSlider
							{...twelvethSliderConfig}
							id={"twelveth_section_slider"}
							borderSliders={2}
						/>
						// <Slider1
						// 	{...twelvethSliderConfig}
						// 	showArrows
						// 	slim={size === 'mobile'}
						// />
					)}
					<Quotes
						bottom
						pageName={pageName}
						section={'twelfth'}
						paragraph={'first'}
					/>
				</section>
			</FadeInSection>
			<Divider d='153' t='130' m='81' />
		</main>
	)
}

export default GlacierUnion
