import {
	useRef,
	useEffect,
	useState, useLayoutEffect,
} from 'react';
import { useWindowMode } from 'utils/useWindowMode';
import s from './Player.module.scss';
import { usePictureMode } from '../../utils/usePictureMode';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Pause } from './video-controls/pause.svg';
import { ReactComponent as Play } from './video-controls/play.svg';
import {
	useOrientation,
	useIntersection,
} from 'react-use';
import { ReactComponent as SoundOn } from './video-controls/sound_on.svg';
import { ReactComponent as SoundOff } from './video-controls/sound_off.svg';
import { ReactComponent as Orientation } from './video-controls/orientation.svg';

export const Player = ({ name, page, section }) => {
	const getPlatform = () => {
		let _result = null;
		const platform = window.navigator.platform;
		const iPhone = [
			'iPhone',
			'Pike v7.6',
			'Pike v7.8'
		];
		const iPad = ['iPad'];
		const iMac = ['Mac'];
		_result = iPhone.some(os => platform.toUpperCase().includes(os.toUpperCase())) ? 'iPhone' : null;
		_result = !_result && iPad.some(os => platform.toUpperCase().includes(os.toUpperCase())) ? 'iPad' : null;
		_result = !_result && iMac.some(os => platform.toUpperCase().includes(os.toUpperCase())) ? 'iMac' : null;
		return _result;
	};
	const size = useWindowMode();
	const mode = usePictureMode();
	const {t, i18n: { language }} = useTranslation();
	const videoRef = useRef(null);
	const containerRef = useRef(null);
	const primaryOrientation = useOrientation() || null;
	const intersection = useIntersection(videoRef, {
		root: null,
		rootMargin: '0px',
		threshold: 1,
	});

	const [orientation, setOrientation] = useState(null);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [playState, setPlayState] = useState('paused')
	const [muted, setMuted] = useState(true);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [played, setPlayed] = useState(false);

	const getOrientation = () => {
		// if (primaryOrientation && primaryOrientation.type === 'portrait-primary') return 'portrait-primary'
		const platform = window.navigator.platform.toUpperCase();
		if (!platform.includes('IPHONE') && !platform.includes('IPAD')) {
			return primaryOrientation && primaryOrientation.type ? primaryOrientation.type : null;
		}
		return window.innerWidth < window.innerHeight || (primaryOrientation && primaryOrientation.type === 'portrait-primary') ? 'portrait-primary' : 'landscape-primary';
		// }
		// return primaryOrientation && primaryOrientation.type ? primaryOrientation.type : null;
	};
	const refreshOrientation = () => {
		const _orientation = getOrientation();
		if(_orientation !== orientation) setOrientation(_orientation);
	}

	const togglePlayState = () => {
		if (playState === 'paused') {
			setPlayed(true)
			videoRef?.current?.play()
			setPlayState('play')
		} else {
			videoRef.current.pause()
			setPlayState('paused')
		}
	}

	const toggleMuteState = () => setMuted(!muted);
	const update = () => setCurrentTime(videoRef?.current?.currentTime * 1000);
	const onmetadata = () => setDuration(videoRef?.current?.duration * 1000);

	const openFullscreen = (elem) => {
		if (elem.requestFullscreen) {
			elem.requestFullscreen()
		} else
			/* Safari */
		if (elem.webkitRequestFullscreen) {
			elem.webkitRequestFullscreen()
		} else
			/* Safari */
		if (elem.webkitEnterFullScreen) {
			elem.webkitEnterFullScreen()
		} else
			/* Mozilla */
		if (elem.mozRequestFullscreen) {
			elem.mozRequestFullScreen()
		} else
			/* IE11 */
		if (elem.msRequestFullscreen) {
			elem.msRequestFullscreen()
		}
		setIsFullscreen(true);
	}

	const closeFullscreen = (elem) => {
		if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement) return;
		if (document.fullscreenElement && document.exitFullscreen) {
			document.exitFullscreen();
		}
		if (document?.exitFullscreen) document.exitFullscreen()
		if (document?.webkitCancelFullScreen) document.webkitCancelFullScreen()
		if (document?.webkitExitFullscreen) document.webkitExitFullscreen()
		if (elem.exitFullscreen) {
			elem.exitFullscreen()
		} else if (elem.webkitExitFullscreen) {
			/* Safari */
			elem.webkitExitFullscreen()
		} else if (elem.msExitFullscreen) {
			/* IE11 */
			elem.msExitFullscreen()
		} else if (elem.mozRequestFullscreen) {
			elem.mozRequestFullScreen()
		} else if (elem.webkitExitFullscreen) {
			// 	/* Safari */
			elem.webkitExitFullscreen()
		}
		setIsFullscreen(false);
	}

	const onpause = () => setPlayState('paused')

	useEffect(() => {
		if (size === 'desktop' && !played) {
			if (intersection && intersection.intersectionRatio === 1) {
				setPlayed(true)
				setPlayState('play')
				videoRef?.current?.play()
			}
		}
		if (played) {
			if (intersection && intersection.intersectionRatio < 1 && !isFullscreen) {
				setPlayState('paused')
				videoRef?.current?.pause()
			}
		}
		// console.log(intersection?.intersectionRatio)
	}, [intersection?.intersectionRatio])

	useEffect(() => {
		if (size === 'mobile' || size === 'tablet') {
			videoRef.current.muted = false;
			setMuted(false);
		}

		if(!orientation) {
			setOrientation(getOrientation() || null);
		}
		let video = videoRef.current;
		videoRef?.current?.addEventListener('loadedmetadata', onmetadata)
		videoRef?.current?.addEventListener('timeupdate', update)
		videoRef?.current?.addEventListener('ended', onpause)
		videoRef?.current?.addEventListener('pause', onpause)

		const videoref_var = videoRef?.current
		return () => {
			videoref_var?.removeEventListener('timeupdate', update)
			videoref_var?.removeEventListener('ended', onpause)
			videoref_var?.removeEventListener('pause', onpause)
			videoref_var?.removeEventListener('loadedmetadata', onmetadata)
		}
	}, []);

	useLayoutEffect(()=>{
		if(orientation && (size === 'mobile' || size === 'tablet')) {
			switch (orientation) {
				case 'landscape-primary':
					setTimeout(() =>{
						const platform = window.navigator.platform.toUpperCase();
						if (playState === 'play') openFullscreen( platform.includes('IPHONE') || platform.includes('IPAD') ? videoRef.current : containerRef.current);
					} ,250);
					break;
				case 'portrait-primary':
					setTimeout(() => {
						const platform = window.navigator.platform.toUpperCase();
						if (playState === 'play' && (!platform.includes('IPHONE') && !platform.includes('IPAD'))) togglePlayState();
						closeFullscreen(platform === 'iPhone' || platform === 'iPad' ? videoRef.current : containerRef.current);
					}, 250);
					break;
			}
		}
	}, [orientation]);

	refreshOrientation();
	// if(orientation === 'landscape-primary') openFullscreen(containerRef.current);

	return (
		<>
			<div
				className={` ${s.wrapper} ${intersection && !played && !intersection.intersectionRatio < 1 ? s.in_view : ''} ${
					playState === 'play' ? s.playing : s.paused
				}`}
			>
				{!played && size === "mobile" &&(
					<div className={` ${s.hint} `}>
						<em>{t('app.buttons.video_hint')}</em>
						<Orientation />
					</div>
				)}

				<button className={` noSelect `} onClick={togglePlayState}>
					{playState === 'paused' ? <Play /> : <Pause />}
				</button>
				<button className={` ${s.sound} noSelect`} onClick={toggleMuteState}>
					{!muted ? <SoundOn /> : <SoundOff />}
				</button>
				{played && (
					<>
						<input
							type='range'
							name=''
							id='inp0'
							min={0}
							step={1000}
							value={currentTime}
							max={duration}
							onChange={e => {
								videoRef.current.currentTime = e.target.value / 1000
							}}
						/>
						<input
							className={` ${s.decoration} `}
							type='range'
							name=''
							id='inp1'
							min={0}
							step={1000}
							value={currentTime}
							max={duration}
							onChange={e => {
								videoRef.current.currentTime = e.target.value / 1000
							}}
						/>
						<input
							className={` ${s.trackbar} `}
							type='range'
							name=''
							id='inp2'
							min={0}
							step={1000}
							value={currentTime}
							max={duration}
							onChange={e => {
								videoRef.current.currentTime = e.target.value / 1000
							}}
						/>
					</>
				)}
				<div className={s.fullscreen_container}
					 ref={containerRef}
				>
					{isFullscreen ? (
						<>
							<button className={`noSelect`} onClick={togglePlayState}>
								{playState === 'paused' ? <Play /> : <Pause />}
							</button>
							<button className={` ${s.sound} noSelect`} onClick={toggleMuteState}>
								{!muted ? <SoundOn /> : <SoundOff />}
							</button>
							{played && (
								<>
									<input
										type='range'
										name=''
										id='inp0'
										min={0}
										step={1000}
										value={currentTime}
										max={duration}
										onChange={e => {
											videoRef.current.currentTime = e.target.value / 1000
										}}
									/>
									<input
										className={` ${s.decoration} `}
										type='range'
										name=''
										id='inp1'
										min={0}
										step={1000}
										value={currentTime}
										max={duration}
										onChange={e => {
											videoRef.current.currentTime = e.target.value / 1000
										}}
									/>
									<input
										className={` ${s.trackbar} `}
										type='range'
										name=''
										id='inp2'
										min={0}
										step={1000}
										value={currentTime}
										max={duration}
										onChange={e => {
											videoRef.current.currentTime = e.target.value / 1000
										}}
									/>
								</>
							)}
						</>
					) : null}
					<video
						alt={t(`pages.${page}.${section}_section.video.alt`)}
						playsInline
						ref={videoRef}
						controls={false} // for hiding IOS controls
						onClick={togglePlayState}
						autoPlay={size === "desktop" ? 'autoplay' : ''}
						muted = {muted}
						poster = {`/images/${page}/jpg_new/${name}Poster${mode}.jpg`}
					>
						{/*<source src={`/videos/${language}/${name}.webm`} type='video/webm; codecs="vp9, opus"' />*/}
						<source src={`/videos/${language}/${name}.mp4`} type='video/mp4' />
					</video>
					{/*<button className={s.exit_fullscreen}*/}
					{/*onClick={()=>closeFullscreen(containerRef)}*/}
					{/*><Orientation /></button>*/}
				</div>
				<div className={s.overlay} />
			</div>
		</>
	)
}
