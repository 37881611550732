import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import s from './Mobile.module.scss';
import {ProducePaths} from "../../../utils";
import {Dots, LeftArrow, Linear, Pull, RightArrow} from "../parts";
import Img from "../../Img/Img";
import {useTranslation} from "react-i18next";
import {useIntersection} from "react-use";

export default function CustomSliderMobile(props) {
    const sleep = 500;
    const borderRepeatCount = props.borderSliders;
    const count = props.slides ? props.slides : 0;
    const id = props.id ? props.id : 'single-slider';
    const [itemIndex, setItemIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const [counterOffset, setCounterOffset] = useState(0);
    const [isAnimation, setIsAnimation] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [touchRec, setTouchRec] = useState({start: 0, move: 0});
    const [width, setWidth] = useState({start: 0, move: 0});
    const [pullShown, setPullShown] = useState(false);
    const [isVisible, setVisible] = useState(false)
    const {page, section, slides, fromStart, noDescription, isLinear, isArrows, hidePull, showTitle} = props;
    const { t, i18n } = useTranslation()
    const {
        slideNames,
        imageAlts,
        slideAlts,
        imageDescriptions,
        titleSlider,
    } = ProducePaths({
        page,
        section,
        slides,
    });
    const slidesImages = slideNames ? slideNames.slice(-borderRepeatCount).concat(slideNames).concat(slideNames.slice(0, borderRepeatCount))
        : [];

    const checkIdx = (idx = itemIndex) => {
        let _idx = idx;
        if (idx < 0) _idx = idx+count;
        if (idx >= count) _idx = idx-count;
        return _idx;
    }

    useEffect(() => {
        setItemIndex(0);
        const onResize = (e) => {
            const w = e.target.innerWidth;
            if (w !== width) setWidth(w);
        }
        window.addEventListener('resize', onResize);
        getOffset(0);
        return () => {window.removeEventListener('resize', onResize)};
    }, []);

    useEffect(()=>{
        if (!isAnimation) setItemIndex(checkIdx(itemIndex));
    }, [isAnimation]);

    useEffect(()=>{
        if(!isClicked && (itemIndex < 0 || itemIndex >= count)) setIsAnimation(false);
    }, [isClicked]);

    useLayoutEffect(()=>{
        getOffset();
        setIsAnimation(false);
    }, [width])

    useLayoutEffect(() => {
        getOffset();
        if (itemIndex !== 0){
            setPullShown(true);
        }
    }, [itemIndex]);

    const ref = useRef(null)
    const wrapperRef = useRef(null)
    const intersection = useIntersection(
        wrapperRef,
        { threshold: 1 }
    )
    useEffect(() => {
        if (intersection?.isIntersecting === true){
            setVisible(true);
            const isPullShown = setTimeout(() => {
                    setPullShown(true);
                }, 3800);
                return () => clearTimeout(isPullShown);
        }
    }, [intersection?.isIntersecting]);
    // useEffect(() => {
    //     const isPullShown = setTimeout(() => {
    //         setPullShown(true);
    //     }, 3800);
    //     return () => clearTimeout(isPullShown);
    // }, []);
    const onInit = (() => {
        let initCount = 0;
        return () => {
            initCount++;
            if (initCount >= (count+borderRepeatCount*2)) getOffset();
        }
    })();

    function getOffset(idx = itemIndex) {
        const targetEl = document.querySelector(`#${id} [data-idx="${idx}"]`);
        const parentEl = document.querySelector(`#${id}`);
        const counterEl = document.querySelector(`#${id} .slider-counter`);
        const space = parseInt(window.getComputedStyle(targetEl, null).getPropertyValue("margin-right"));
        const _offset = fromStart ?
            parentEl.offsetWidth / 2 - (targetEl.offsetLeft + targetEl.offsetWidth / 2) - space * 1.5
            :
            parentEl.offsetWidth / 2 - (targetEl.offsetLeft + targetEl.offsetWidth / 2) + 20;
        const alignBySlide = (parentEl.offsetWidth + targetEl.offsetWidth) / 2 - counterEl.offsetWidth - 100;
        const alignByWidth = parentEl.offsetWidth - counterEl.offsetWidth - 20;
        setCounterOffset(alignBySlide<alignByWidth ? alignBySlide : alignByWidth);
        setOffset(_offset);
    }

    const onClick = (e, isNext) => {
        setIsAnimation(true);
        if(isClicked) return;
        let idx = isNext ? itemIndex + 1 : itemIndex - 1;
        setItemIndex(idx);
        setIsClicked(true);
        setTimeout(()=>{setIsClicked(false)}, sleep);
    }

    const handleClickDots = (ind) => {
        setItemIndex(ind);
        setIsClicked(true);
        setTimeout(()=>{setIsClicked(false)}, sleep);
    }

    const onSwipe = (e) => {
        e.stopPropagation();
        if (e.touches.length > 1) {
            return false;
        }
        const clientX = Array.from(e.targetTouches).reduce((acc, {clientX}) => {
            return acc + clientX;
        }, 0) / Array.from(e.targetTouches).length;
        const _scrollEl = document.querySelector('html');
        const _touchRec = Object.assign({}, touchRec);
        switch (e.type) {
            case 'touchstart':
                _scrollEl.style.overflow = 'hidden';
                _touchRec.start = clientX;
                break;
            case 'touchmove':
                _touchRec.move = clientX - touchRec.start;
                setIsAnimation(false);
                break;
            case 'touchend':
                _scrollEl.style.overflow = 'inherit';
                setIsAnimation(true);
                const _width = e.target.offsetWidth;
                if (Math.abs(_touchRec.move) > _width / 4 && _touchRec.start !== 0) {
                    let _idx = itemIndex;
                    _idx = _touchRec.move > 0 ? onClick(null, false) : onClick(null, true);
                }
                _touchRec.start = 0;
                _touchRec.move = 0;
                break;
        }
        if (Math.abs(_touchRec.start - touchRec.start) >= 1 || Math.abs(_touchRec.move - touchRec.move) >= 1)
            setTouchRec(_touchRec);
    }

    const renderChildren = () => slidesImages.map((slide, idx) => (
        <div className={`${s.slideContainer}`} data-idx={idx - borderRepeatCount} key={idx - borderRepeatCount}>
            <Img src={slide} className={s.sliderWrapper} />
        </div>
    ));

    return (<div style={{display: 'flex', flexDirection: 'column'}} className={s.gridSlider}>
        <div id={id} className={`${s.sliderWrapper}`} ref={wrapperRef}>
            {isArrows && (<div className={`${s.buttonContainer} ${s.left}`}>
                <LeftArrow
                    className={`${s.arrow} ${s.left} ${s.white}`}
                    onClick={(e) => onClick(e,false)}
                />
            </div>)}
            <div className={`${s.newSlider} ${s.sliderContainer}`} onTouchStart={onSwipe} onTouchEnd={onSwipe} onTouchMove={onSwipe}  style={{position: 'relative'}}>
                {!hidePull && <Pull isVisible={isVisible} hide={pullShown}/>}
                <div className={`slider-counter ${s.sliderCounter}`} style={{transform: `translate(${counterOffset}px, 0)`}}>
                    {checkIdx(itemIndex)+1}/{count}
                </div>
                <div ref={ref} className={`${s.slideInline}`} style={{transform: `translate(${offset+touchRec.move}px, 0)`, transition: `${isAnimation ? `transform ${sleep}ms ease-out` : 'none'}`}}>
                    {renderChildren()}
                </div>
            </div>
            {isArrows && (<div className={`${s.buttonContainer} ${s.right}`}>
                <RightArrow
                    className={`${s.arrow} ${s.right} ${s.white}`}
                    onClick={(e) => onClick(e,true)}
                />
            </div>)}
        </div>
        <div>
            {showTitle && (
                <>
                    <p className={s.titleForMobileSlider}>{t(titleSlider[checkIdx(itemIndex)])}</p>
                    <p className={s.descriptionForMobileSlider}>{t(imageDescriptions[checkIdx(itemIndex)])}</p>
                </>
            )}
            {!noDescription && (
                <em className={s.emForMobileSlider}>
                    {t(
                        `pages.${page}.${section}_section.description`
                    ) ===
                    `pages.${page}.${section}_section.description`
                        ? t(imageDescriptions[checkIdx(itemIndex)])
                        : t(
                            `pages.${page}.${section}_section.description`
                        )}
                </em>
            )}
            {isLinear && (<Linear currentPart={checkIdx(itemIndex)+1} partsCount={slides} click={handleClickDots} />)}
            {!isLinear && (<Dots currentPart={checkIdx(itemIndex)+1} partsCount={slides} click={handleClickDots} />)}
        </div>
        </div>
    );
}
