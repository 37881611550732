import Img from '../Img/Img'
import { useTranslation } from 'react-i18next'
import React, { forwardRef, useState } from 'react'
import s from './SliderVHTablet.module.scss'
import { RightArrow, LeftArrow } from './parts'
import { wrap } from 'popmotion'
import { ProducePaths, usePictureMode } from '../../utils'
import { useRef } from 'react'

export const SliderVHTablet = ({
                                             page,
                                             section,
                                             slides,
                                             className,
                                             noDescription,
                                             showCounter,
                                         }) => {
    const [slim, setSlim] = useState(undefined)
    const mode = usePictureMode()
    const { t } = useTranslation()
    // const ref = forwardRef(null)
    const [current, setCurrent] = useState(0)
    const {
        slideNames,
        imageAlts,
        imageDescriptions,
    } = ProducePaths({
        page,
        section,
        slides,
    });

    const getFullScreenSlidersData = (itemIndex = 0) => {
        const fsSliders = slideNames.map((sl, key) => ({
            src: sl.replace('.jpg', 'FS.jpg').replace('/jpg/', '/jpg_new/'),
            alt: t(imageAlts[key])
        }));
        return JSON.stringify(fsSliders.slice(wrap(0, slides, current)).concat(fsSliders.slice(0, wrap(0, slides, current))));
    };

    return (
        <>
            <div
                className={`${className} ${s.switcher}`}
            >
                {showCounter &&
                    <div className={`${s.sliderCounter}`} style={current === 1 && window.screen.availWidth < 870 ? {color: "#FFFFFF"} : {color: "#566c91"} }>
                        {current+1}/{slides}
                    </div>
                }
                <Img
                    onLoad={e => {
                        setSlim(e.target.naturalWidth < e.target.naturalHeight)
                        // console.log(
                        // 	e.target.naturalWidth < 600
                        // 		? 'slim'
                        // 		: 'wide'
                        // )
                    }}
                    loading='eager'
                    className={`${s.active} ${s.image}`}
                    src={
                        slideNames[wrap(0, slides, current)]
                    }
                    alt={t(
                        imageAlts[wrap(0, slides, current)]
                    )}
                    style={{
                        // alignItems: 'stretch',
                        gridColumn: slim ? '6 / span 7'
                                    : '2/span 15'
                        ,
                    }}
                    fullScreenMode
                    dataFullScreen={getFullScreenSlidersData(current)}
                />
                <Img
                    loading='eager'
                    className={`${s.image} ${s.fourth} ${s.rightImg}`}
                    src={
                        slideNames[
                            wrap(0, slides, current + 1)
                            ]
                    }
                    alt={t(
                        imageAlts[
                            wrap(0, slides, current + 1)
                            ]
                    )}
                    style={{
                        maxHeight: "100px",
                        width: "160px",
                        gridColumn: "13/span 4",
                        gridRow: '2/span 2',
                    }}
                />


                {!noDescription &&
                t(
                    imageDescriptions[
                        wrap(0, slides, current)
                        ]
                ) !== '' && (
                    <div className={s.description} style={{
                        gridColumn: slim ? "2 / span 3" : "2/span 11",
                        gridRow: slim ? "2" : "3",
                        alignSelf: slim ? 'end' : 'end',
                    }}>
                        {t(
                            imageDescriptions[
                                wrap(0, slides, current)
                                ]
                        )}
                    </div>
                )}
                <>
                    <LeftArrow
                        className={`${s.left} ${s.arrow} ${current === 1 ? s.white : s.black}`}
                        onClick={() => {
                            setCurrent( current - 1 >= 0 ?
                                current_slide => current_slide - 1 : current_slide => slides-1
                            )
                            console.log(current)
                        }
                        }
                    />
                    <RightArrow
                        className={`${s.right} ${s.arrow} ${current === 1 ? s.white : s.black}`}
                        onClick={() => {
                            setCurrent(current + 1 !== slides ?
                                current_slide => current_slide + 1 : current_slide => 0
                            )
                            console.log(current);
                        }
                        }
                    />
                </>
            </div>
            {/* <pre>{JSON.stringify(ref, null, 2)}</pre> */}
        </>
    )
}
