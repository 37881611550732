import Carousel from "react-multi-carousel";
import { capitalize } from "../../utils/utils";
import { usePictureMode } from "../../utils/usePictureMode";
import Img from "components/Img/Img";
import "react-multi-carousel/lib/styles.css";
import "./Slider1.scss";
import withTranslation from "../App";
import { useTranslation } from "react-i18next";
import {LeftArrow, RightArrow, Dot, Pull, Linear} from "./parts";
import { useWindowMode } from "utils";
import { useRef, useState, useEffect } from "react";
import { ProducePaths } from "../../utils/pathGen";
import { wrap } from "popmotion";
import { useIntersection } from 'react-use'

const gutter = 352
// const gutter = 337
const gutterTab = 100

function getOriginalCounterPart(
	index,
	{ slidesToShow, currentSlide },
	slides
) {
	// this function is only used for "infinite and showDots are true";
	if (slides > slidesToShow * 2) {
		const originalCouterPart =
			index + slidesToShow * 2
		return originalCouterPart
	} else {
		if (currentSlide >= slides) {
			return slides + index
		} else {
			return index
		}
	}
}

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		// partialVisibilityGutter: 156,
		items: 1,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1199 },
		items: 1,
		partialVisibilityGutter: 400,
	},
	tablet: {
		breakpoint: { max: 1199, min: 767 },
		partialVisibilityGutter: 0,
		items: 1,
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		partialVisibilityGutter: 0,
		items: 1,
	},
}
const responsive2 = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		// partialVisibilityGutter: 156,
		items: 1,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1200 },
		items: 1,
		partialVisibilityGutter: gutter,
	},
	tablet: {
		breakpoint: { max: 1199, min: 768 },
		partialVisibilityGutter: gutterTab,
		items: 1,
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		partialVisibilityGutter: 0,
		items: 1,
	},
}

export const Slider1 = ({
	className,
	page,
	section,
	slides,
	debug,
	partial,
	noDots,
	noPull,
	showLinear,
	fromStart,
	showArrows,
	whiteArrows,
	showTitle,
	slim,
	noDescription,
	sliderStyle = {}
}) => {
	const mode = usePictureMode()
	const device = useWindowMode()
	const { t, i18n } = useTranslation()
	const [pullShown, setPullShown] = useState(true)
	const [currentSlide, setCurrentSlide] =
		useState(0)

	const ref = useRef(null)
	const {
		slideNames,
		imageAlts,
		slideAlts,
		imageDescriptions,
		titleSlider,
	} = ProducePaths({
		page,
		section,
		slides,
	});
	const ButtonGroup = ({
			 next,
			 previous,
			 goToSlide,
			 ...rest
		}) => (
		<>
			<LeftArrow
				className={`arrow left ${whiteArrows ? "white" : "black"}`}
				onClick={() => previous()}
			/>
			<RightArrow
				className={`arrow right ${whiteArrows ? "white" : "black"}`}
				onClick={() => next()}
			/>
		</>
	)
	const getFullScreenSlidersData = (sliders = [], itemIndex = 0) => {
		const fsSliders = sliders.map((sl, key) => ({
			src: sl.replace('.jpg', 'FS.jpg').replace('/jpg/', '/jpg_new/'),
			alt: t(slideAlts[key])
		}));
		return JSON.stringify(fsSliders.slice(wrap(0, slides, itemIndex)).concat(fsSliders.slice(0, wrap(0, slides, itemIndex))));
	};

	const [isVisible, setVisible] = useState(false)
	const wrapperRef = useRef(null)
	const intersection = useIntersection(
		wrapperRef,
		{ threshold: 1 }
	)
	useEffect(() => {
		if (intersection?.isIntersecting === true){
			setVisible(true)
		}
	}, [intersection?.isIntersecting])
	return (
		<>
			<div
				ref={wrapperRef}
				className={
					className +
					' slider1 ' +
					`${
						partial ? 'three_images_partial' : ''
					} ${slim ? 'slim_slider_1' : ''}`
				}
			>
				{device !== 'desktop' && !noPull && (
					<Pull
						isVisible={isVisible}
						hide={!pullShown}
					/>
				)}
				<Carousel
					ref={ref}
					keyBoardControl
					// draggable={false}
					afterChange={!fromStart ? (_, state, nn) => {
							wrap(
								0,
								slides,
								getOriginalCounterPart(
									state.currentSlide,
									{
										slidesToShow:
											state.slidesToShow,
										currentSlide:
											state.currentSlide,
									},
									slides
								)
							)
						setCurrentSlide(
							wrap(
								0,
								slides,
								state.currentSlide - 2
							)
						)
						setPullShown(false)
						} : (_, state, nn) => {

						setCurrentSlide(state.currentSlide)


						setPullShown(false)
						}
					}
					arrows={false}
					infinite = {!fromStart}
					// centerMode
					additionalTransfrom={
						device === 'desktop'
							? gutter / 2
							: partial && !fromStart
							? gutterTab / 2
							: 0
					}
					responsive={
						!partial ? responsive : responsive2
					}
					// responsive={responsive}
					partialVisible={
						device === 'desktop' || partial
					}
					customButtonGroup={
						!showArrows ?
							<ButtonGroup />
							:
							null
					}
					dotListClass='dots_container'
					renderDotsOutside
					showDots={
						noDots ? false : true
					}
					customDot={device !== 'desktop' ? <Dot /> : null}
				>
					{slideNames.map((image, key) => (
						<div className={'autosize-wrapper'} key={key}>
							<Img
								src={image}
								key={key}
								alt={t(slideAlts[key])}
								loading='eager'
								fullScreenMode={key === currentSlide}
								dataFullScreen={getFullScreenSlidersData(slideNames, key)}
								style={sliderStyle}
							/>
						</div>
					))}
				</Carousel>
				{showLinear && device !== 'mobile' &&<Linear currentPart={currentSlide + 1} partsCount={slides} />}
				{showTitle && (
					<>
						<p className="title">{t(titleSlider[currentSlide])}</p>
						<p className="description">{t(imageDescriptions[currentSlide])}</p>
					</>
				)}
				{!noDescription && (
					<em>
						{t(
							`pages.${page}.${section}_section.description`
						) ===
						`pages.${page}.${section}_section.description`
							? t(imageDescriptions[currentSlide])
							: t(
									`pages.${page}.${section}_section.description`
							  )}
					</em>
				)}
				{showLinear && device === 'mobile' && <Linear currentPart={currentSlide + 1} partsCount={slides} />}
			</div>
			{debug && (
				<pre>
					{JSON.stringify(
						{
							pullShown,
							slideNames,
							slideAlts,
							imageDescriptions,
							partial,
							noDots,
							additionalTransfrom:
								device === 'desktop'
									? gutter / 2
									: partial
									? gutterTab / 2
									: 0,
						},
						null,
						2
					)}
				</pre>
			)}
		</>
	)
}
// export default withTranslation()(Slider1)
