import s from './NovolazarevskayaStationShirmahersOasis.module.scss'
import {
	usePageName,
	useWindowMode,
	usePictureMode,
} from 'utils'
import Img from 'components/Img/Img'
import { useTranslation } from 'react-i18next'
import SwitchTwoImages from 'components/SwitchTwoImages/SwitchTwoImages'
import { Slider1 } from 'components/Sliders/Slider1'
import {SliderVerticalHorizontal as SwitchWH} from 'components/Sliders/SliderVerticalHorizontal'
import { Switcher as DeskSwitcher } from 'components/Sliders/Desk/Switcher'
import { Switcher as DeskSwitcher2 } from 'components/Sliders/Desk/SwitcherFiveImages'
import { SwitcherTab as TabletSwitcher } from 'components/Sliders/Tab/SwitcherTab'
import AuthorLayout from 'components/AuthorLayout/AuthorLayout'
// import Quotes from 'components/Quotes/Quotes'
// import { Divider } from 'components/Divider'
import { Player } from 'components/VideoJS/Player'
import FadeInSection from 'components/FadeInOut/FadeSection'
import CustomSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import CustomSliderTab from "../../components/Sliders/NewSliders/Tablet/Tablet";
import {useLayoutEffect, useState} from "react";
import CustomSliderMobile from "../../components/Sliders/NewSliders/Mobile";


const NovolazarevskayaStationShirmahersOasis =
	() => {
		const { t } = useTranslation()
		const pageName = usePageName()
		const size = useWindowMode()
		const tr = slice => {
			return t(`pages.${pageName}.${slice}`)
		}

		const mode = usePictureMode()
		const basePicturesUrl = `/images/` + pageName
		const fourthSliderConfig = {
			className: s.slider,
			page: pageName,
			section: 'fourth',
			slides: 5,
		}
		const sixthSliderConfig = {
			className: s.slider,
			page: pageName,
			section: 'sixth',
			slides: 5,
		}

		const ninthSliderConfig = {
			className: s.slider,
			page: pageName,
			section: 'ninth',
			slides: 3,
		}

		return (
			<main className={` ${s.page} `}>
				<FadeInSection>
					<section className={s.pagePreview}>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
							alt={tr(
								'first_section.alts.image1'
							)}
						/>
						<h1 className={s.title}>
							{tr('first_section.title')}
						</h1>
						<div className={s.coordinates}>
							<img
								src='/icons/LOCATION_ICON.svg'
								alt='LOCATION ICON'
							/>
							<h2>
								{tr('first_section.coordinates')}
							</h2>
						</div>
						<div className={s.height}>
							<img
								src='/icons/altitude_icon.svg'
								alt='altitude ICON'
							/>
							<h2>
								{tr('first_section.height')}
							</h2>
						</div>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.second} `}>
						<h3>{tr('second_section.title')}</h3>
						<p>
							{tr(
								'second_section.first_paragraph'
							)}
						</p>
						<figure>
							<Img
								className={s.image}
								src={`${basePicturesUrl}/jpg/SecondSectionImage1${mode}.jpg`}
								alt={tr(
									'second_section.alts.image1'
								)}
								fullScreenMode
							/>
							<figcaption>
								{tr('second_section.description')}
							</figcaption>
						</figure>
						<h4>
							{tr('second_section.heading')}
						</h4>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.third} `}>
						<SwitchTwoImages
							pageName={pageName}
							section={'third'}
							fix
						/>
						<p>
							{tr(
								'third_section.first_paragraph'
							)}
						</p>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.fourth} `}>
						{size === 'desktop' ? (
							<SwitchWH
								className='slider'
								page={pageName}
								section={'fourth'}
								noDots
								fromStart
								slides={5}
							/>
						) : size === 'tablet' ? (
							<CustomSliderTab {...fourthSliderConfig} id = {"fourth_section_slider"} borderSliders={3} isLinear />
						) : (
							<CustomSliderMobile
								{...fourthSliderConfig} id = {"fourth_section_slider"} borderSliders={3} isLinear isArrows hidePull
							/>
						)}
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.fifth} `}>
						<figure>
							<Img
								className={s.image}
								src={`${basePicturesUrl}/jpg/FifthSectionImage1${mode}.jpg`}
								alt={tr(
									'fifth_section.alts.image1'
								)}
								fullScreenMode
							/>
							<figcaption>
								{tr('fifth_section.description')}
							</figcaption>
						</figure>
						<figure>
							<Img
								className={s.image}
								src={`${basePicturesUrl}/jpg/FifthSectionImage2${mode}.jpg`}
								alt={tr(
									'fifth_section.alts.image2'
								)}
								fullScreenMode
							/>
							<Img
								className={s.image}
								src={`${basePicturesUrl}/jpg/FifthSectionImage3${mode}.jpg`}
								alt={tr(
									'fifth_section.alts.image3'
								)}
								fullScreenMode
							/>
						</figure>
						<p>
							{tr(
								'fifth_section.first_paragraph'
							)}
						</p>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.sixth} `}>
						{/* TODO: make slider */}
						{size === 'desktop' ? (
							<DeskSwitcher
								{...sixthSliderConfig}
								// fiveImages
							/>
						) : size === 'tablet' ? (
							<TabletSwitcher {...sixthSliderConfig} />
						) : (
							// <TabletSwitcher
							// 	{...fifthSliderConfig}
							// />
							<CustomSliderMobile
								{...sixthSliderConfig} id = {"sixth_section_slider"} borderSliders={3}  isArrows
							/>
						)}
						<h4>{tr('sixth_section.heading')}</h4>
						<p>
							{tr(
								'sixth_section.first_paragraph'
							)}
						</p>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.seventh} `}>
						{size === "desktop" ?
							<CustomSlider page = {pageName} section = {"seventh"} slides = {5} id = {"seventh_section_slider"} borderSliders={3} className = {s.slider} noDescription/>
							:
							size === "tablet" ?
							<CustomSliderTab page = {pageName} section = {"seventh"} slides = {5} id = {"seventh_section_slider"} borderSliders={3} className = {s.slider} noDescription isLinear/>
							:
							<CustomSliderMobile page = {pageName} section = {"seventh"} slides = {5} id = {"seventh_section_slider"} borderSliders={3} className = {s.slider} hidePull noDescription isLinear isArrows/>
						}
						{/*<Slider1*/}
						{/*	className={s.slider}*/}
						{/*	page={pageName}*/}
						{/*	section='seventh'*/}
						{/*	slides={5}*/}
						{/*	noPull*/}
						{/*	noDescription*/}
						{/*	noDots*/}
						{/*	fromStart={size === "tablet"}*/}
						{/*	showLinear = {size === "tablet" || size === "mobile"}*/}
						{/*	partial = {size === "tablet"}*/}
						{/*	whiteArrows={size === "tablet" || size === "mobile"}*/}
						{/*/>*/}
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.eighth} `}>
						<figure>
							<Img
								className={s.image}
								src={`${basePicturesUrl}/jpg/EighthSectionImage1${mode}.jpg`}
								alt={tr(
									'eighth_section.alts.image1'
								)}
								fullScreenMode
							/>
							<Img
								className={s.image}
								src={`${basePicturesUrl}/jpg/EighthSectionImage2${mode}.jpg`}
								alt={tr(
									'eighth_section.alts.image2'
								)}
								fullScreenMode
							/>
							{/* <figcaption>
							{tr('eighth_section.description')}
						</figcaption> */}
						</figure>
						<p>
							{tr(
								'eighth_section.first_paragraph'
							)}
						</p>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.ninth} `}>
						{size === 'desktop' ? (
							<DeskSwitcher
								{...ninthSliderConfig}
								// fiveImages
							/>
						) : size === 'tablet' ? (
							<TabletSwitcher
								{...ninthSliderConfig}
								whiteArrows
							/>
						) : (
							// <TabletSwitcher
							// 	{...fifthSliderConfig}
							// />
							<CustomSliderMobile {...ninthSliderConfig} id = {"ninth_section_slider"} borderSliders={3} className = {s.slider} isArrows/>
						)}
						<p>
							{tr(
								'ninth_section.first_paragraph'
							)}
						</p>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.tenth} `}>
						{size === "desktop" ?
							<CustomSlider page = {pageName} section = {"tenth"} slides={3} id = {"tenth_section_slider"} borderSliders={3} className = {s.slider}/>
							:
							size === "tablet" ?
							<CustomSliderTab page = {pageName} section = {"tenth"} slides={3} id = {"tenth_section_slider"} borderSliders={3} className = {s.slider} isLinear noDescription/>
							:
							<CustomSliderMobile page = {pageName} section = {"tenth"} slides={3} id = {"tenth_section_slider"} borderSliders={3} className = {s.slider} isArrows hidePull isLinear/>
						}
						{/*<Slider1*/}
						{/*	page={pageName}*/}
						{/*	section={'tenth'}*/}
						{/*	slides={3}*/}
						{/*	className={s.slider}*/}
						{/*	partial={size === 'tablet'}*/}
						{/*	showLinear={size !== 'desktop'}*/}
						{/*	fromStart={size === 'tablet'}*/}
						{/*	noDescription={size === "tablet"}*/}
						{/*	noDots*/}
						{/*	noPull={size !== 'desktop'}*/}
						{/*	whiteArrows={size !== "desktop"}*/}
						{/*	liStyle={{margin: '0 15px'}}*/}
						{/*	sliderStyle={{height: '100%'}}*/}
						{/*	// noDescription*/}
						{/*/>*/}
						<h3>{tr('tenth_section.title')}</h3>
						<h4>{tr('tenth_section.heading')}</h4>
						<p>
							{tr(
								'tenth_section.first_paragraph'
							)}
						</p>
						<Player
							name={'2.Sounds'}
							page={pageName}
							section={'tenth'}
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.eleventh} `}>
						<h4>
							{tr('eleventh_section.heading')}
						</h4>
						<AuthorLayout
							pageName={pageName}
							section={'eleventh'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.twelfth} `}>
						<AuthorLayout
							pageName={pageName}
							section={'twelfth'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section
						className={` ${s.thirteenth} `}
					>
						<AuthorLayout
							pageName={pageName}
							section={'thirteenth'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section
						className={` ${s.fourteenth} `}
					>
						<AuthorLayout
							pageName={pageName}
							section={'fourteenth'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.fifteenth} `}>
						<AuthorLayout
							pageName={pageName}
							section={'fifteenth'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.sixteenth} `}>
						<AuthorLayout
							pageName={pageName}
							section={'sixteenth'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
				<FadeInSection>
					<section className={` ${s.seventeenth} `}>
						<AuthorLayout
							pageName={pageName}
							section={'seventeenth'}
							fix
							quotes
						/>
					</section>
				</FadeInSection>
			</main>
		)
	}

export default NovolazarevskayaStationShirmahersOasis
