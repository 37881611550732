import Img from "components/Img/Img";
import { usePageName } from "utils";
import "./WhatIsAntarctica.scss";
import { withTranslation } from "react-i18next";
import { usePictureMode } from "../../utils/usePictureMode";
import { getLanguage } from '../../utils/customLanguageDetector'

import { useEffect, useRef } from 'react'
import { Slider1 } from '../../components/Sliders/Slider1'
import { Divider } from 'components/Divider'
import { Slider2 } from 'components/Sliders/Slider2/Slider2'
import Carousel from 'react-multi-carousel'
import { Slider2Tab } from '../../components/Sliders/Slider2/Slider2Tab'
import { useWindowMode } from '../../utils/useWindowMode'
import { Player } from '../../components/VideoJS/Player'
import FadeInSection from "components/FadeInOut/FadeSection";
import DesktopSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import TabletSlider from "../../components/Sliders/NewSliders/Tablet/Tablet";
import MobileSlider from "../../components/Sliders/NewSliders/Mobile";


const WhatIsAntarctica = ({ t }) => {
	const pageName = usePageName()
	const size = useWindowMode();
	const tr = slice =>
		t(`pages.${pageName}.${slice}`)

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName

	const tenthSectionConfig = {
		className: "slider",
		page: pageName,
		section: "tenth",
		slides: 3,
	};

	const twelveSectionConfig = {
		className: "slider",
		page: pageName,
		section: "twelfth",
		slides: 6,
	};

	return (
		<div className={pageName}>
			<FadeInSection>
				<section className='pagePreview'>
					<h1 className='title'>
						{tr('first_section.title')}
					</h1>
					<Img
						className='image'
						src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
						alt={tr('first_section.alts.image1')}
					/>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='secondSection'>
					<h2>{tr('second_section.title')}</h2>
					<p className='first_paragraph'>
						{tr('second_section.first_paragraph')}
					</p>
					<Img
						className='image'
						src={`${basePicturesUrl}/jpg/SecondSectionBg${mode}_${getLanguage()}.jpg`}
					/>
					<em className='description'>
						{tr('second_section.description')}
					</em>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='thirdSection'>
					<p className='first_paragraph'>
						{tr('third_section.first_paragraph')}
					</p>
					<p className='second_paragraph'>
						{tr('third_section.second_paragraph')}
					</p>
					<Img
						className='image img1'
						src={`${basePicturesUrl}/jpg/ThirdSectionImg1${mode}.jpg`}
						style={{width: mode==='Tab' || mode==='Mob' ? '254px' : '368px', margin: '0 auto'}}
					/>
					<Img
						className='image img2'
						src={`${basePicturesUrl}/jpg/ThirdSectionImg2${mode}.jpg`}
						style={{width: mode==='Tab' || mode==='Mob' ? '254px' : '368px', margin: '0 auto'}}
					/>
					<em className='description'>
						{tr('third_section.description')}
					</em>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='fourthSection'>
					<Player
						name={'3.Landscapes'}
						page={pageName}
						section={'fourth'}
					/>
					<p className='first_paragraph'>
						{tr('fourth_section.first_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='fifthSection'>
					<h2 className='title'>
						{tr('fifth_section.title')}
					</h2>
					<Img
						alt={tr('fifth_section.alts.image1')}
						className='image'
						src={`${basePicturesUrl}/jpg/FifthSectionMap_${getLanguage()}${mode}.jpg`}
					/>
					<p className='first_paragraph'>
						{tr('fifth_section.first_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='sixthSection'>
					<Img
						alt={tr('sixth_section.alts.image1')}
						className='image'
						src={`${basePicturesUrl}/jpg/SixthSectionBg${mode}.jpg`}
						fullScreenMode
					/>
					<p className='first_paragraph'>
						{tr('sixth_section.first_paragraph')}
					</p>
					<em className='description'>
						{tr('sixth_section.description')}
					</em>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='seventhSection'>
					<p className='first_paragraph'>
						{tr(
							'seventh_section.first_paragraph'
						)}
					</p>
					<Img
						className='image1'
						src={`${basePicturesUrl}/jpg/SeventhSectionImg1${mode}.jpg`}
						fullScreenMode
					/>
					<Img
						className='image2'
						src={`${basePicturesUrl}/jpg/SeventhSectionImg2${mode}.jpg`}
						fullScreenMode
					/>
					<em className='description'>
						{tr('seventh_section.description')}
					</em>
					<p className='second_paragraph'>
						{tr(
							'seventh_section.second_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='eighthSection'>
					<h3 className='title'>
						{tr('eighth_section.title')}
					</h3>
					<p className='first_paragraph'>
						{tr('eighth_section.first_paragraph')}
					</p>
					<Img
						className='image1'
						src={`${basePicturesUrl}/jpg/EighthSectionBg${mode}.jpg`}
					/>
					<em className='description'>
						{tr('eighth_section.description')}
					</em>
					<p className='second_paragraph'>
						{tr(
							'eighth_section.second_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='ninthSection'>
					<h3 className='title'>
						{tr('ninth_section.title')}
					</h3>
					<Img
						alt={tr('ninth_section.alts.image1')}
						className='image'
						src={`${basePicturesUrl}/jpg/NinthSectionBg${mode}.jpg`}
						fullScreenMode
					/>
					<em className='description'>
						{tr('ninth_section.description')}
					</em>
					<p className='first_paragraph'>
						{tr('ninth_section.first_paragraph')}
					</p>
					<p className='second_paragraph'>
						{tr('ninth_section.second_paragraph')}
					</p>
				</section>
			</FadeInSection>

			<Divider d={100} t={80} m={40} />
			<FadeInSection>
				<section className='tenthSectionSlider'>
					{/*<CustomSlider {...tenthSectionConfig} id={'tenth_section_slider'}  borderSliders={2}/>*/}
					{size === 'desktop' ? (
						<DesktopSlider {...tenthSectionConfig} id={'tenth_section_slider'}  borderSliders={3}/>
					) : size === 'tablet' ? (
						<TabletSlider {...tenthSectionConfig} id={"tenth_section_slider"} borderSliders={3} isLinear whiteArrows/>
					) : (
						<MobileSlider
							{...tenthSectionConfig}
							id={"tenth_section_slider"}
							borderSliders={3}
							isArrows={true}
							isLinear={true}
							hidePull={true}
						/>
					)}
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='eleventhSection'>
					<p className='first_paragraph'>
						{tr(
							'eleventh_section.first_paragraph'
						)}
					</p>
					<p className='second_paragraph'>
						{tr(
							'eleventh_section.second_paragraph'
						)}
					</p>
					<ul>
						<li className={'logoWrapper1'}>
							<Img
								alt={tr(
									'eleventh_section.alts.logo1'
								)}
								className='logo1'
								src={`${basePicturesUrl}/jpg/EleventhSectionLogo1.jpg`}
								style={{maxHeight: '96px', objectFit: 'cover'}}
							/>
							<h4>
								{tr(
									'eleventh_section.firstTitleBlock'
								)}
							</h4>
							<p className='firstParagraphBlock'>
								{tr(
									'eleventh_section.firstParagraphBlock'
								)}
							</p>
						</li>
						<li className={'logoWrapper2'}>
							<Img
								alt={tr(
									'eleventh_section.alts.logo3'
								)}
								className='logo3'
								src={`${basePicturesUrl}/jpg/EleventhSectionLogo3.jpg`}
								style={{maxHeight: '96px', objectFit: 'cover'}}
							/>
							<h4>
								{tr(
									'eleventh_section.thirdTitleBlock'
								)}
							</h4>
							<p className='thirdParagraphBlock'>
								{tr(
									'eleventh_section.thirdParagraphBlock'
								)}
							</p>
						</li>
						<li className={'logoWrapper3'}>
							<div>
								<Img
									alt={tr(
										'eleventh_section.alts.logo2'
									)}
									className='logo2'
									src={`${basePicturesUrl}/jpg/EleventhSectionLogo2.jpg`}
									style={{maxHeight: '80px', objectFit: 'cover'}}
								/>
							</div>
							<h4>
								{tr(
									'eleventh_section.secondTitleBlock'
								)}
							</h4>
							<p className='secondParagraphBlock'>
								{tr(
									'eleventh_section.secondParagraphBlock'
								)}
							</p>
						</li>
					</ul>
				</section>
			</FadeInSection>
			<Divider d={140} t={76} m={80} />
			<FadeInSection>
				<section className='twelfthSection'>
					<p className='first_paragraph'>
						{tr(
							'twelfth_section.first_paragraph'
						)}
					</p>
					{size === 'desktop' ? (
						<Slider2
							className='slider'
							page={pageName}
							section={'twelfth'}
							slides={6}
						/>
					) : size === "tablet" ? (
						<Slider2Tab
							className='slider'
							page={pageName}
							section={'twelfth'}
							slides={6}
						/>
					) : (
							 <MobileSlider
							 	{...twelveSectionConfig}
							 	id={"twelfth_section_slider"}
							 	borderSliders={3}
							 	hidePull={false}
								showTitle={true}
								noDescription={true}
							 />
					)}
				</section>
			</FadeInSection>
			<FadeInSection>
				<section className='thirteenSection'>
					<h3>{tr('thirteen_section.title')}</h3>
					<p>
						{tr(
							'thirteen_section.first_paragraph'
						)}
					</p>
					<ol>
						<li>
							{tr('thirteen_section.list.first')}
						</li>
						<li>
							{tr('thirteen_section.list.second')}
						</li>
						<li>
							{tr('thirteen_section.list.third')}
						</li>
						<li>
							{tr('thirteen_section.list.four')}
						</li>
						<li>
							{tr('thirteen_section.list.five')}
						</li>
						<li>
							{tr('thirteen_section.list.six')}
						</li>
						<li>
							{tr('thirteen_section.list.seven')}
						</li>
						<li>
							{tr('thirteen_section.list.eight')}
						</li>
					</ol>
					<Img
						alt={tr(
							'thirteen_section.alts.image1'
						)}
						className='image'
						src={`${basePicturesUrl}/jpg/ThirteenSectionBg${mode}.jpg`}
					/>
				</section>
			</FadeInSection>
			{/* <Divider d={50} t={20} m={20} /> */}
		</div>
	)
}

export default withTranslation()(WhatIsAntarctica);
