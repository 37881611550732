import React from 'react'
import MainIconClosed from './MainIconClosed.svg'
import Facebook from './Facebook.svg'
import FacebookHover from './Facebook_hover.svg'
import Telegram from './Telegram.svg'
import TelegramHover from './Telegram_hover.svg'
import Vkontakte from './Vkontakte.svg'
import VkontakteHover from './Vkontakte_hover.svg'
import CopyLink from './CopyLink.svg'
import CopyLinkHover from './CopyLink_hover.svg'
import MainIconOpened from './MainIconOpened.svg'
import s from './ShareButton.module.scss'
import { useState, useEffect } from 'react';
import { useWindowMode } from 'utils'
import { withTranslation } from 'react-i18next'
import { useTranslation } from 'react-i18next'

export const ShareButton = () => {
    const [openButton, setOpenButton] = useState(false);
    const [bottom, setBottom] = useState(' ');
    const [isLinkСopied, setIsLinkСopied] = useState(false);
    const mode = useWindowMode();
    const {t} = useTranslation();
    
    const onScroll =()=> {
        const height = document.documentElement.scrollHeight -
        document.documentElement.scrollTop - window.innerHeight;
        if (mode === 'tablet') {
            if (height < 225) {
                setBottom(330 - height)
            } else {
                setBottom('12.01vh')
            }
        } else if (mode === 'mobile') {
            if (height < 225) {
                setBottom(395 - height)
            }
        }
    }

    const onExitClick = (e) => {
        const { target } = e;
        if(!target.closest(`#shareBar`)) setOpenButton(false);
    };

    useEffect(()=> {
        document.addEventListener("scroll", onScroll);
        document.addEventListener("click", onExitClick);
        return ()=>{
            document.removeEventListener("scroll", onScroll);
        }
    })

    const isCanCopy = () => navigator.clipboard || document.queryCommandSupported('copy');

    const copyLink = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(window.location.href).catch(e => console.error(e))
        } else if(document.queryCommandSupported('copy')) {
            const el = document.querySelector('#linkNow');
            const rng = document.createRange()
            rng.selectNode(el);
            window.getSelection().addRange(rng);
            document.execCommand('copy');
        }
        setIsLinkСopied(true);
        window.addEventListener('hashchange', () => setIsLinkСopied(false), {once: "true"});
    }

    return (
        <div id={'shareBar'} className={s.mainWrapper}>
            <span id={'linkNow'}  style={{display: 'none'}}>{window.location.href}</span>
            <div
                className={`${s.wrapper} ${openButton && s.paddingInsideWrapper}`} 
            >
            {openButton ?
                <>
                    <img 
                        onClick={()=>setOpenButton(prevState=>!prevState)} 
                        src={MainIconOpened}
                        style={{width:'21px', height:'24px'}}
                        alt="" 
                    />
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&amp;src=sdkpreparse`} target='_blank' className={`${s.buttonWithHover} socialBtn`}>
                        <img src={Facebook} alt="" />
                        <img src={FacebookHover} alt="" />
                    </a>
                    <a href={`https://telegram.me/share/url?url=${encodeURIComponent(window.location.href)}`} target='_blank' className={`${s.buttonWithHover} socialBtn`}>
                        <img src={Telegram} alt="" />
                        <img src={TelegramHover} alt="" />
                    </a>
                    <a href={`http://vk.com/share.php?url=${encodeURIComponent(window.location.href)}`} target='_blank' className={`${s.buttonWithHover} socialBtn`}>
                        <img src={Vkontakte} alt="" />
                        <img src={VkontakteHover} alt="" />
                    </a>
                    {isCanCopy ? (
                        <a onClick={copyLink} target='_blank' className={isLinkСopied ? s.buttonActive : `${s.buttonWithHover} socialBtn`}>
                            <div>
                                <img src={CopyLink} alt="" />
                            </div>
                            <div>
                                {
                                    isLinkСopied ? (
                                        <div className={s.descriptorWrapper}><span className={s.descriptor}>{t('shareButton.link_copied')}</span></div>
                                    ) : (
                                        mode === 'desktop' ? (
                                            <div className={s.descriptorWrapper}><span className={s.descriptor}>{t('shareButton.copy_link')}</span></div>
                                        ) : null
                                    )
                                }
                                <img src={CopyLinkHover} alt="" />
                            </div>
                        </a>
                    ) : null}
                </>
            :
            <img 
                onClick={()=>setOpenButton(prevState=>!prevState)} 
                src={MainIconClosed} 
                alt="" 
            />
            }
        </div>
        </div>
    )
}
export default withTranslation()(ShareButton)
