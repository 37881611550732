import { capitalize } from '../../../utils/utils'
import { usePictureMode } from '../../../utils/usePictureMode'
import Img from 'components/Img/Img'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import s from './imageSwitcher.module.scss'
import { RightArrow, LeftArrow } from '../parts'
import { wrap } from 'popmotion'
import { ProducePaths } from '../../../utils/pathGen'

export const Switcher = ({
	page,
	section,
	slides,
	className,
	highActive,
    style = {}
}) => {
	const mode = usePictureMode()
	const { t } = useTranslation()

	const [current, setCurrent] = useState(0)
	const [slim, setSlim] = useState(undefined)
	const {
		slideNames,
		slideAlts,
		imageDescriptions,
	} = ProducePaths({
		page,
		section,
		slides,
	})

	const getFullScreenSlidersData = (itemIndex = 0) => {
		const fsSliders = slideNames.map((sl, key) => ({
			src: sl.replace('.jpg', 'FS.jpg'),
			alt: t(slideAlts[key])
		}));
		return JSON.stringify(fsSliders.slice(wrap(0, slides, itemIndex)).concat(fsSliders.slice(0, wrap(0, slides, itemIndex))));
	};

	return (
		<>
			<div
				className={`${className} ${s.switcher} ${
					highActive && s.highActive
				}`}
				style = {{gridTemplateRows: slim ?
						'[active-start] 340px[secondary-start] 79px [end] 81px'
						:
						"[active-start] 340px [secondary-start] 79px [end] 81px",
					height: "581px",
				}}
			>
				<Img
					className={`${s.image} ${s.first}`}
					src={
						slideNames[
							wrap(0, slides, current + 1)
						]
					}
					alt={t(
						slideAlts[
							wrap(0, slides, current + 1)
						]
					)}
				/>
				<Img
					className={`${s.image} ${s.second}`}
					src={
						slideNames[
							wrap(0, slides, current + 2)
						]
					}
					alt={t(
						slideAlts[
							wrap(0, slides, current + 2)
						]
					)}
				/>
				<Img
					onLoad={e => {
						setSlim(e.target.naturalWidth < e.target.naturalHeight)
					}}
					className={`${s.active} ${s.image}`}
					src={
						slideNames[wrap(0, slides, current)]
					}
					alt={t(
						slideAlts[wrap(0, slides, current)]
					)}
					fullScreenMode
					dataFullScreen={getFullScreenSlidersData(current)}
					style={{gridColumn: slim ? '5/ span 4' : '4/span 6', gridRow: slim ? "active/span 4" : "active/span 3", height: slim ? "581px" : "100%"}}
				/>

				{t(
					imageDescriptions[
						wrap(0, slides, current)
					]
				) !== '' && (
					<div className={s.description}>
						{t(
							imageDescriptions[
								wrap(0, slides, current)
							]
						)}
					</div>
				)}
				<div className={`${s.arrows}`}>
					<p className={s.counter}>{`${
						wrap(0, slides, current) + 1
					}/${slides}`}</p>
					<RightArrow
						onClick={() => {
							setCurrent(
								current_slide => current_slide + 1
							)
						}
						}
					/>
					<LeftArrow
						onClick={() =>
							setCurrent(
								current_slide => current_slide - 1
							)
						}
					/>
				</div>
			</div>
		</>
	)
}
