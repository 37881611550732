// import { capitalize } from '../../../utils/utils'
import { usePictureMode } from "../../../utils/usePictureMode";
import Img from "components/Img/Img";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import s from "./Slider2Tab.module.scss";
import { wrap } from "popmotion";
import { ProducePaths } from "utils/pathGen";
import { LeftArrow,RightArrow } from '../parts'
import { useWindowMode } from "utils";
import Carousel from "react-multi-carousel";
import { Pull } from "../parts";
import { useRef } from 'react';
import { useEffect } from 'react';

export const Slider2Tab = ({ page, section, slides }) => {
  const mode = usePictureMode();
  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  // useEffect(() =>{
  //   setCount(count + 1)
  // })


  const instance = useRef(null)
  const { slideNames, imageAlts, imageDescriptions, titleSlider } =
    ProducePaths({
      page,
      section,
      slides,
    });
    const [isVisible, setVisible] = useState(true)
    const wrapperRef = useRef(null)
	const [pullShown, setPullShown] = useState(true)

    const Miniature = data => {
        return (
            <Img
                src={
                    slideNames[
                        wrap(0, slides, data.index + 1)
                        ]
                }
                className={
                    data.active ? s.visible : s.miniature
                }
                onClick={() => {
                    instance.current.next()
                }}
            />
        )
    }

    useEffect(() => {
      let currentRef = wrapperRef.current
      let observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            let toSetInvisible =
              !entry.isIntersecting &&
              entry.boundingClientRect.top > 0
            setVisible(!toSetInvisible)
          })
        }
      )
      observer.observe(currentRef)
      return () => {
        if (currentRef) {
          return observer.unobserve(currentRef)
        }
      }
    }, [])
  if (useWindowMode() === "tablet" || 'mobile') {
      const ButtonGroup = ({
               next,
               previous,
               goToSlide,
               ...rest
           }) => (
          <>
              <LeftArrow
                  className={`arrow left ${s.arrow} ${s.left}`}
                  onClick={() => previous()}
              />
              <RightArrow
                  className={`arrow right ${s.arrow} ${s.right}`}
                  onClick={() => next()}
              />
          </>
      )
      return (
          <div ref={wrapperRef}>

              <Carousel
                  itemClass={s.card}
                  additionalTransfrom={0}
                  ref={instance}
                  renderDotsOutside
                  showDots
                  dotListClass={s.miniatures}
                  arrows={false}
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  containerClass="container-with-dots"
                  draggable
                  afterChange={
                      () => setPullShown(false)
                  }
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  customDot={<Miniature />}
                  customButtonGroup={
                      <ButtonGroup/>
                  }
                  responsive={{
                      desktop: {
                          breakpoint: {
                              max: 3000,
                              min: 1024,
                          },
                          items: 1,
                      },
                      mobile: {
                          breakpoint: {
                              max: 464,
                              min: 0,
                          },
                          items: 1,
                      },
                      tablet: {
                          breakpoint: {
                              max: 1024,
                              min: 464,
                          },
                          items: 1,
                      },
                  }}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
              >
                  {slideNames.map((image, key) => (
                      <Fragment key={key}>
                          <Img className={s.image} src={image} alt={t(imageAlts[key])}/>
                          <p className={s.title}>{t(titleSlider[key])}</p>
                          <p className={s.description}>{t(imageDescriptions[key])}</p>
                          <p className={s.counter}>
                              {key + 1}/{slides}
                          </p>
                      </Fragment>
                  ))}
              </Carousel>
          </div>
      );
  } else
  return null;
};
