const preventDefaultFn = (e) => {
    e.preventDefault();
}

const onDomContentLoaded = () => {
    const imgArr = document.querySelectorAll('img');
    imgArr.forEach(img => {
            img.addEventListener('contextmenu', preventDefaultFn);
            img.addEventListener('mousedown', preventDefaultFn);
    });
}

export const disableDownloadImagesCleanup = () => {
    const imgArr = document.querySelectorAll('img');
    imgArr.forEach(img => {
        img.removeEventListener('mousedown', preventDefaultFn);
        img.removeEventListener('contextmenu', preventDefaultFn);
    });
    document.removeEventListener("DOMContentLoaded",  onDomContentLoaded);
}

export function disableDownloadImages() {
    document.addEventListener("DOMContentLoaded",  onDomContentLoaded, { once: true });
}
