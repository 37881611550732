import { usePictureMode } from './usePictureMode'
import { capitalize } from './utils'

export const ProducePaths = ({
	page,
	section,
	slides,
}) => {
	const slideNames = []
	const imageAlts = []
	const slideAlts = []
	const imageDescriptions = []
	const members = []
	const titleSlider = []

	const mode = usePictureMode()

	for (let index = 0; index < slides; index++) {
		slideNames.push(
			`/images/${page}/jpg_new/${capitalize(
				section
			)}SectionSlide${index + 1}${mode}.jpg`
		)

		imageAlts.push(
			`pages.${page}.${section}_section.image${
				index + 1
			}.alt`
		)
		imageDescriptions.push(
			`pages.${page}.${section}_section.image${
				index + 1
			}.description`
		)
		slideAlts.push(
			`pages.${page}.${section}_section.alts.slide${
				index + 1
			}`
		)
		members.push(
			`/images/${page}/jpg_new/${capitalize(
				section
			)}SectionTeamMember${index + 1}${mode}.jpg`
		)

		titleSlider.push(
			`pages.${page}.${section}_section.image${
				index + 1
			}.title`
		)
	}

	return {
		slideNames,
		imageAlts,
		slideAlts,
		imageDescriptions,
		members,
		titleSlider,
	}
}
