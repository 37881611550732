import s from "./ImgDesc.module.scss";
import { withTranslation } from "react-i18next";
import { usePictureMode } from "../../utils/usePictureMode";
import "video.js/dist/video-js.css";
import Img from "components/Img/Img";
import classNames from "classnames/bind";
import { getLanguage } from '../../utils/customLanguageDetector';
import { capitalize } from "utils/utils";

const ImgDesc = ({ t, section, pageName, left, right, map, fullWidth, normal, fullScreenMode, dataFullScreen, fullScreenButtonStyle, imgClassName }) => {
  const tr = (slice) => t(`pages.${pageName}.${slice}`);

  const mode = usePictureMode();
  const basePicturesUrl = `/images/` + pageName;
    const cx = classNames.bind(s);
  return (
    <figure
      className={cx({
        imgDescription: true,
        left,
        right,
        fullWidth,
        normal
      })}
    >
      {!map ? (
        <>
          <Img
            className={`${s.image} ${imgClassName ? imgClassName : ''}`}
            src={`${basePicturesUrl}/jpg/${capitalize(section)}SectionBg${mode}.jpg`}
            alt={tr(`${section}_section.alts.image1`)}
            fullScreenMode = {fullScreenMode}
            dataFullScreen={dataFullScreen}
            fullScreenButtonStyle={fullScreenButtonStyle}
          />
          <figcaption>
            {tr(`${section}_section.description`)}
          </figcaption>
        </>
      ) : (
        <>
            <Img
              className={`${s.image} ${imgClassName ? imgClassName : ''}`}
              src={`${basePicturesUrl}/jpg/${capitalize(section)}SectionMap_${getLanguage()}${mode}.jpg`}
              alt={tr(`${section}_section.alts.image1`)}
              fullScreenMode = {fullScreenMode}
              dataFullScreen={dataFullScreen}
              fullScreenButtonStyle={fullScreenButtonStyle}
            />
            <figcaption>
            {tr(`${section}_section.image1.description`)}
            </figcaption>
        </>
      )}
      
    </figure>
  );
}

export default withTranslation()(ImgDesc);
