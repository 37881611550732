import { useState, useEffect } from 'react'
const breakpoints = {
	mobile: 768,
	tablet: 1200,
}
export const useWindowMode = () => {
	function getSize() {
		return (window.innerWidth < breakpoints.mobile) ? 'mobile' :
			(window.innerWidth < breakpoints.tablet) ?
				'tablet' : 'desktop';
	}

	const [windowSize, setWindowSize] =
		useState(getSize)
	// window.onload
	useEffect(() => {
		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener(
			'resize',
			handleResize
		)
		return () => {
			window.removeEventListener(
				'resize',
				handleResize
			)
		}
	}, [])

	return windowSize
}
