import './Img.scss';
import { ReactComponent as FullScreenButton} from "./FullScreenBtn.svg";
import { useWindowMode } from "./../../utils";
import { useState } from "react";

const Img = ({
	src,
	wrapperClass,
	expand,
	ref,
	fullScreenMode,
	dataFullScreen,
	fullScreenButtonStyle,
	alt,
	...rest
}) => {
	const mode = useWindowMode()
	const [isLoad, setIsLoad] = useState(false);


	const genFullScreen = (isPrefix = false) => (
		{
			src: !isPrefix ? src.replaceAll('webp', 'jpg').replace('/jpg/', '/jpg_new/') :
				src.replaceAll('webp', 'jpg').replace('/jpg/', '/jpg_new/').replace('.jpg', 'FS.jpg'),
			alt: alt
		}
	)

	const genZoom = (source) => {
		source = source.replaceAll('webp', 'jpg').replace('/jpg/', '/jpg_new/').replace('.jpg', 'Load.jpg')
		return source
	}

	// const getLoadStyle = () => isLoad ? {opacity: 1} : {opacity: 0.1};
	const handleOnLoad = (e) => {
		setIsLoad(true);
	}

	const genContent = () => (
		<>
			<source
				srcSet={genFullScreen().src}
				type='image'
			/>
			{fullScreenMode && mode === 'desktop' ? (
				<div style = {{position: "relative"}}>
					<img
						loading='lazy'
						src={genFullScreen().src}
						alt={genFullScreen().alt}
						{...rest}
						fullScreenMode
						dataFullScreen = {dataFullScreen ? dataFullScreen : JSON.stringify([genFullScreen(true)])}
						// onLoad={onLoad}
					/>
					<div
						className={'fullscreen_button'}
						style = {fullScreenButtonStyle ? fullScreenButtonStyle : {}}
						dataFullScreen = {dataFullScreen ? dataFullScreen : JSON.stringify([genFullScreen(true)])}
					>
						<FullScreenButton
							dataFullScreen = {dataFullScreen ? dataFullScreen : JSON.stringify([genFullScreen(true)])}
						/>
					</div>
				</div>
			) : mode === "mobile" || mode === "tablet" ? (
				<div className={`lazyWrapper ${isLoad ? 'loaded' : '' }`}>
					<img
						{...rest}
						loading='eager'
						src={genFullScreen().src}
						alt={genFullScreen().alt}
						// style = {isLoad ? {display : "none"} : rest.style}
					/>
					<img
						{...rest}
						src={genZoom(src)}
						alt={genFullScreen().alt}
						onLoad={handleOnLoad}
						loading='lazy'
						// style = {isLoad ? rest.style : {display: "none"}}
					/>
				</div>
			) : (
				<img
					loading='lazy'
					src={genFullScreen().src}
					alt={genFullScreen().alt}
					{...rest}
					// onLoad={onLoad}
				/>
			)}
		</>
	);

	return fullScreenMode ? (
		<picture
			style={rest.style}
			className={`${rest.className} ${fullScreenMode ? 'cursorPointer':''}`}
			fullScreenMode={fullScreenMode ? true : false}
		>
			{genContent()}
		</picture>
	) : (
		<picture
			style={rest.style}
			className={rest.className}
		>
			{genContent(true)}
		</picture>
	)
};

export default Img
