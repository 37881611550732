import { useTranslation, withTranslation } from "react-i18next";
import './FullScreen.scss';
import { useEffect, useLayoutEffect, useState } from "react";
import {ReactComponent as ArrowInCircleLeftIcon} from './arrow-in-circle-left.svg';
import {ReactComponent as ArrowInCircleLeftActiveIcon} from './arrow-in-circle-left-active.svg';
import {ReactComponent as ArrowInCircleRightIcon} from './arrow-in-circle-right.svg';
import {ReactComponent as ArrowInCircleRightActiveIcon} from './arrow-in-circle-right-active.svg';
import {ReactComponent as ExitFullscreen} from './exit_fullscreen.svg';


export const FullScreen = (props) => {
    // const mode = useWindowMode();
    const [itemIndex, setItemIndex] = useState(0);
    const {t} = useTranslation();
    const sliders = props.sliders;
    const count = props.sliders.length;
    const setSliders = props.setSliders;

    useEffect(()=>{
        const elBackground = document.querySelector('#content');
        const elBody = document.querySelector('body');
        const onExit = (e) => {
            e.stopPropagation();
            const elParent = e.target.closest('#slider_wrapper');
            const elExit = e.target.closest('.button_exit');
            if (elExit || !elParent && !elExit) {
                setSliders([]);
            }
        };
        elBackground.style.filter = 'blur(20px)';
        elBody.style.overflow = 'hidden';
        elBody.addEventListener('click', onExit);
        return () => {
            elBackground.style.filter = 'none';
            elBody.style.overflow = 'visible';
            elBody.removeEventListener('click', onExit);
        }
    }, []);

    useLayoutEffect(()=>{
        refreshSliders();
    }, [itemIndex]);

    const onPrevent = (e) => {
        e.preventDefault();
    }

    const getSliders = () => {
        return (<div className={'slider_container'}>
            {sliders.map((slider, key) => (
                <img src={slider.src} alt={t(slider.alt)} key={key} data-idx={key} style={{display: key === itemIndex ? 'block' : 'none'}} onContextMenu={onPrevent} onMouseDown={onPrevent}/>
            ))}
        </div>);
    }

    const refreshSliders = () => {
        const _images = document.querySelectorAll('img[data-idx]');
        _images.forEach((img) => {
            if(img.attributes['data-idx'].value !== itemIndex.toString(10)) img.style.display = 'none';
            else img.style.display = 'block';
        });
    }

    const onSwipe = (e, isNext) => {
        let idx = isNext ? itemIndex + 1 : itemIndex - 1;
        if (idx >= count) idx = idx - count;
        if (idx < 0) idx = idx + count;
        setItemIndex(idx);
    }

    return (
        <div className={'fullscreen_wrapper'}>
            <div className={'slider_container'}>
                <div id={'slider_wrapper'} className={'slider_wrapper'}>
                    { count > 1 ? (
                        <div className={'button_left'} onClick={(e) => onSwipe(e,false)}>
                            <ArrowInCircleLeftIcon/>
                            <ArrowInCircleLeftActiveIcon/>
                        </div>
                    ) : null }
                    { getSliders() }
                    { count > 1 ? (
                        <div className={'button_right'} onClick={(e) => onSwipe(e,true)}>
                            <ArrowInCircleRightIcon/>
                            <ArrowInCircleRightActiveIcon/>
                        </div>
                    ) : null }
                    <div className={'button_exit'}><ExitFullscreen/></div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(FullScreen)