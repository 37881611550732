import s from "./PoleNotAvailabilityCamp.module.scss";
import { withTranslation } from "react-i18next";
import { Divider } from "components/Divider";
import Img from "components/Img/Img";
import { usePageName, usePictureMode, useWindowMode } from "utils";
import { Player } from "components/VideoJS/Player";
import { Switcher } from "../../components/Sliders/Desk/Switcher";
import { Slider1 } from "components/Sliders/Slider1";
import { SwitcherTab } from "components/Sliders/Tab/SwitcherTab";
import ImgDesc from "components/ImgDesc/ImgDesc";
import AuthorLayout from "components/AuthorLayout/AuthorLayout";
import Quotes from "components/Quotes/Quotes";
import FadeInSection from "components/FadeInOut/FadeSection";
import CustomSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import CustomSliderTab from "../../components/Sliders/NewSliders/Tablet/Tablet";
import CustomSliderMobile from "../../components/Sliders/NewSliders/Mobile";
import TabletSlider from "../../components/Sliders/NewSliders/Tablet/Tablet";

const PoleNotAvailabilityCamp = ({ t }) => {
  const pageName = usePageName();
  const mode = usePictureMode();
  const size = useWindowMode();

  const tr = (slice) => t(`pages.${pageName}.${slice}`);

  const basePicturesUrl = `/images/` + pageName;

  const fourthSectionSlider = {
    className: s.slider,
    page: pageName,
    section: "fourth",
    slides: 5,
  };
  const seventeenthSectionSlider={
    className: s.slider,
    page: pageName,
    section: "seventeenth",
    slides: 5
  }
  return (
		<main className={s.page}>
			<FadeInSection>
				<section className={s.firstSection}>
					<Img
						className={s.image}
						src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
						alt={tr('first_section.alts.image1')}
					/>
					<h1 className={s.title}>
						{tr('first_section.title')}
					</h1>
					<div className={s.coordinates}>
						<img
							src='/icons/LOCATION_ICON.svg'
							alt='LOCATION ICON'
						/>
						<h2>
							{tr('first_section.coordinates')}
						</h2>
					</div>
					<div className={s.height}>
						<img
							src='/icons/altitude_icon.svg'
							alt='altitude ICON'
						/>
						<h2>{tr('first_section.height')}</h2>
					</div>
				</section>
			</FadeInSection>

			<Divider d='120' t='100' m='80' />
			<FadeInSection>
				<section className={s.secondSection}>
					<h2>{tr('second_section.title')}</h2>
					<p>
						{tr('second_section.first_paragraph')}
					</p>
					<Player
						name={'6.Pole_of_inaccessibility'}
						page={pageName}
						section={'second'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={s.thirdSection}>
					<p>
						{tr('third_section.first_paragraph')}
					</p>
					<Img
						alt={tr('third_section.alts.image1')}
						src={`${basePicturesUrl}/jpg/ThirdSectionBg${mode}.jpg`}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='40' />
			<FadeInSection>
				<section className={s.fourthSection}>
					<h4>
						{tr('fourth_section.first_paragraph')}
					</h4>
					<p>
						{tr(
							'fourth_section.second_paragraph'
						)}
					</p>
					{size === 'desktop' ? (
						<Switcher {...fourthSectionSlider}
								  highActive
						/>
					) : size === 'tablet' ? (
						<SwitcherTab
							{...fourthSectionSlider}
						/>
					) : (
						<CustomSliderMobile {...fourthSectionSlider} id={"fourth_section_slider"} borderSliders={3} isDots/>
					)}
				</section>
			</FadeInSection>
			<Divider d='220' t='100' m='80' />
			<FadeInSection>
				<section className={s.fifthSection}>
					<h1>{tr('fifth_section.title')}</h1>
					<h4>
						{tr('fifth_section.first_paragraph')}
					</h4>
					<p>
						{tr('fifth_section.second_paragraph')}
					</p>
					{size === "desktop" ?
						<CustomSlider id={"fifth_section_slider"} className={s.slider} page={pageName} section={"fifth"} slides={3} borderSliders={3}/>
						:
						size === "tablet" ?
						<CustomSliderTab id={"fifth_section_slider"} className={s.slider} page={pageName} section={"fifth"} slides={3} borderSliders={3} isLinear/>
						:
						<CustomSliderMobile id={"fifth_section_slider"} className={s.slider} page={pageName} section={"fifth"} hidePull isLinear isArrows slides={3} borderSliders={3}/>
					}

					{/*<Slider1*/}
					{/*	className={s.slider}*/}
					{/*	page={pageName}*/}
					{/*	section='fifth'*/}
					{/*	slides={3}*/}
					{/*	noPull={size === 'mobile'}*/}
					{/*	partial={size !== 'desktop'}*/}
					{/*	fromStart={size !== 'desktop'}*/}
					{/*	showLinear={size !== 'desktop'}*/}
					{/*	whiteArrows={size === "mobile"}*/}
					{/*	noDots*/}
					{/*/>*/}
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='52' />
			<FadeInSection>
				<section className={s.sixthSection}>
					<h4>
						{tr('sixth_section.first_paragraph')}
					</h4>
					<p>
						{tr('sixth_section.second_paragraph')}
					</p>
					<ImgDesc
						section='sixth'
						pageName={pageName}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={s.seventhSection}>
					<p>
						{tr(
							'seventh_section.first_paragraph'
						)}
					</p>
					<figure>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/SeventhSectionImage1${mode}.jpg`}
							alt={tr(
								'seventh_section.alts.image1'
							)}
							fullScreenMode
						/>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/SeventhSectionImage2${mode}.jpg`}
							alt={tr(
								'seventh_section.alts.image2'
							)}
							fullScreenMode
						/>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/SeventhSectionImage3${mode}.jpg`}
							alt={tr(
								'seventh_section.alts.image3'
							)}
							fullScreenMode
						/>
					</figure>
					<p>
						{tr(
							'seventh_section.second_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>
				<section className={s.eighthSection}>
					<figure>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/EighthSectionImage1${mode}.jpg`}
							alt={tr(
								'eighth_section.alts.image1'
							)}
							fullScreenMode
						/>
						<Img
							className={s.image}
							src={`${basePicturesUrl}/jpg/EighthSectionImage2${mode}.jpg`}
							alt={tr(
								'eighth_section.alts.image2'
							)}
							fullScreenMode
						/>
						<figcaption>
							{tr('eighth_section.description')}
						</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<Divider d='100' t='100' m='80' />
			<FadeInSection>
				<section className={s.ninthSection}>
					<AuthorLayout
						pageName={pageName}
						section={'ninth'}
						fix
						quotes
					/>
				</section>
			</FadeInSection>
			<Divider d='221' t='127' m='97' />
			<FadeInSection>
				<section className={s.tenthSection}>
					<h3>{tr('tenth_section.title')}</h3>
					<h4>
						{tr('tenth_section.first_paragraph')}
					</h4>
					<Player
						name={'10.Everyday_life'}
						page={pageName}
						section={'tenth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={s.eleventhSection}>
					<h4>
						{tr(
							'eleventh_section.first_paragraph'
						)}
					</h4>
					<p>
						{tr(
							'eleventh_section.second_paragraph'
						)}
					</p>
					<figure>
						<Img
							src={`${basePicturesUrl}/jpg/EleventhSectionImage1${mode}.jpg`}
							alt={tr(
								'eleventh_section.alts.image1'
							)}
							fullScreenMode
						/>
						<Img
							src={`${basePicturesUrl}/jpg/EleventhSectionImage2${mode}.jpg`}
							alt={tr(
								'eleventh_section.alts.image2'
							)}
							fullScreenMode
						/>
						<Img
							src={`${basePicturesUrl}/jpg/EleventhSectionImage3${mode}.jpg`}
							alt={tr(
								'eleventh_section.alts.image3'
							)}
							fullScreenMode
						/>
					</figure>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className={s.twelfthSection}>
					<p>
						{tr(
							'twelfth_section.first_paragraph'
						)}
					</p>
					<p>
						{tr(
							'twelfth_section.second_paragraph'
						)}
					</p>
					<figure>
						<Img
							src={`${basePicturesUrl}/jpg/TwelfthSectionImage1${mode}.jpg`}
							alt={tr(
								'twelfth_section.alts.image1'
							)}
							fullScreenMode
						/>
						<Img
							src={`${basePicturesUrl}/jpg/TwelfthSectionImage2${mode}.jpg`}
							alt={tr(
								'twelfth_section.alts.image2'
							)}
							fullScreenMode
						/>
						<figcaption>
							{tr('twelfth_section.description')}
						</figcaption>
					</figure>
					<p>
						{tr(
							'twelfth_section.third_paragraph'
						)}
					</p>
					<p>
						{tr(
							'twelfth_section.fourth_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='140' t='100' m='80' />
			<FadeInSection>
				<section className={s.thirteenthSection}>
					<AuthorLayout
						pageName={pageName}
						section={'thirteenth'}
						fix
						quotes
					/>
				</section>
			</FadeInSection>
			<Divider d='129' t='63' m='57' />
			<FadeInSection>
				<section className={s.fourteenthSection}>
					<figure>
						<Img
							src={`${basePicturesUrl}/jpg/FourteenthSectionBg${mode}.jpg`}
							alt={tr(
								'fourteenth_section.alts.image1'
							)}
						/>
						<figcaption>
							{tr(
								'fourteenth_section.description'
							)}
						</figcaption>
					</figure>
				</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>
				<section className={s.fifteenthSection}>
					<p>
						{tr(
							'fifteenth_section.first_paragraph'
						)}
					</p>
					<Player
						name={'9.Storm'}
						page={pageName}
						section={'fifteenth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='140' t='100' m='80' />
			<FadeInSection>
				<section className='sixteenthSection'>
					<AuthorLayout
						pageName={pageName}
						section={'sixteenth'}
						fix
						quotes
					/>
				</section>
			</FadeInSection>
			<Divider d='130' t='68' m='60' />
			<FadeInSection>
				<section className={s.seventeenthSection}>
					{size === 'desktop' ? (
						<Switcher
							{...seventeenthSectionSlider}
							highActive
						/>
					) : size === 'tablet' ? (
						<SwitcherTab
							{...seventeenthSectionSlider}
						/>
					) : (
						<CustomSliderMobile
							{...seventeenthSectionSlider} id={"seventeenth_section_slider"} borderSliders={3} isDots
						/>
					)}
				</section>
			</FadeInSection>
			<Divider d='70' t='100' m='80' />
		</main>
	)
};

export default withTranslation()(PoleNotAvailabilityCamp);
