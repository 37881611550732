import s from "./SwitchTwoImages.module.scss";
import Img from "components/Img/Img";
import { usePictureMode } from "utils/usePictureMode";
import { withTranslation } from "react-i18next";
import { capitalize } from "utils/utils";
import { useState } from 'react';
import ShowExpand from './ShowExpand.svg';
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'

const transition = {
	initial: { opacity: 0.5 },
	animate: { opacity: 1 },
	exit: { opacity: 0.5 },
}

const SwitchTwoImages = ({ pageName, section, t, fix }) => {
	const tr = slice => t(`pages.${pageName}.${slice}`)

	const [imageIndex, setImageIndex] = useState(1)

	const mode = usePictureMode();
	const basePicturesUrl = numImg => {
		if (fix) return `/images/${pageName}/jpg/${capitalize(section)}SectionImage${numImg}${mode}.jpg`
		return `/images/${pageName}/jpg/${capitalize(section)}SectionImg${numImg}${mode}.jpg`
	}
	const getFullScreenSlidersData = (itemIndex = 1) => {
		const sliders = [basePicturesUrl(itemIndex), basePicturesUrl(itemIndex === 1 ? 2 : 1)];
		const fsSliders = sliders.map((sl, key) => ({
			src: sl.replace('.jpg', 'FS.jpg').replace('/jpg/', '/jpg_new/'),
			alt: tr(`${section}_section.image${imageIndex}.alt`)
		}));
		return JSON.stringify(fsSliders);
	};
	return (
		<section className={s.SwitchTwoImages} layout>
			<em key={imageIndex + 10} {...transition}>
				{tr(`${section}_section.image${imageIndex}.description`)}
			</em>
			<div key={imageIndex + 30} className={s.mainImg} {...transition}>
				{mode === 'Tab' || mode === 'Mob' ? (
					<Img
						className={s.mainImg}
						src={basePicturesUrl(imageIndex)}
						onClick={() => setImageIndex(imageIndex === 1 ? 2 : 1)}
						alt={tr(`${section}_section.image${imageIndex}.alt`)}
						fullScreenMode
						dataFullScreen={getFullScreenSlidersData(imageIndex)}
					/>
				):(
					<Img
						className={s.mainImg}
						src={basePicturesUrl(imageIndex)}
						// onClick={() => setImageIndex(imageIndex === 1 ? 2 : 1)}
						alt={tr(`${section}_section.image${imageIndex}.alt`)}
						fullScreenMode
						dataFullScreen={getFullScreenSlidersData(imageIndex)}
					/>
				)}

			</div>
			<div
				className={s.miniatureImg}
				key={imageIndex}
				{...transition}
				onClick={() => setImageIndex(imageIndex === 1 ? 2 : 1)}
			>
				<Img
					alt={tr(`${section}_section.image${imageIndex === 1 ? 2 : 1}.alt`)}
					src={basePicturesUrl(imageIndex === 1 ? 2 : 1)}

				/>
				<img className={s.showExpand} src={ShowExpand} />
			</div>
		</section>
	)
}

export default withTranslation()(SwitchTwoImages);
