import s from "./Quotes.scss";
import { withTranslation } from "react-i18next";
import { usePictureMode } from "../../utils/usePictureMode";
import "video.js/dist/video-js.css";
import classNames from "classnames/bind";

const Quotes = ({ t, pageName, section, paragraph, bottom, top, none, nonebottom }) => {
  const tr = (slice) => t(`pages.${pageName}.${slice}`);

  const mode = usePictureMode();

  const lower = function () {
    return section.toLowerCase();
  };
  const cx = classNames.bind(s);
  return (
    <blockquote
      className={cx({
        quotes: true,
        bottom,
        top,
        none
      })}
    >
      <em>{tr(`${lower(section)}_section.${paragraph}_paragraph`)}</em>
    </blockquote>
  );
};

export default withTranslation()(Quotes);
