import s from './IntermediateAirportAircraft.module.scss'
import {
	usePageName,
	useWindowMode,
	usePictureMode,
} from 'utils'
import Img from 'components/Img/Img'
import { useTranslation } from 'react-i18next'
import SwitchTwoImages from 'components/SwitchTwoImages/SwitchTwoImages'
import { Slider1 } from 'components/Sliders/Slider1'
import { Switcher as DeskSwitcher } from 'components/Sliders/Desk/Switcher'
import { SwitcherTab as TabletSwitcher } from 'components/Sliders/Tab/SwitcherTab'
import AuthorLayout from 'components/AuthorLayout/AuthorLayout'
import Quotes from 'components/Quotes/Quotes'
import { Divider } from 'components/Divider'
import { Player } from 'components/VideoJS/Player'
import FadeInSection from 'components/FadeInOut/FadeSection'

const IntermediateAirportAircraft = () => {
	const { t } = useTranslation()
	const pageName = usePageName()
	const size = useWindowMode()
	const tr = slice => {
		return t(`pages.${pageName}.${slice}`)
	}

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName

	return (
		<div className={` ${s.page} `}>
			<FadeInSection>
			<section className={s.pagePreview}>
				<Img
					className={s.image}
					src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
					alt={tr('first_section.alts.image1')}
				/>
				<h1 className={s.title}>
					{tr('first_section.title')}
				</h1>
				<div className={s.coordinates}>
					<img
						src='/icons/LOCATION_ICON.svg'
						alt='LOCATION ICON'
					/>
					<h2>
						{tr('first_section.coordinates')}
					</h2>
				</div>
				<div className={s.height}>
					<img
						src='/icons/altitude_icon.svg'
						alt='altitude ICON'
					/>
					<h2>{tr('first_section.height')}</h2>
				</div>
			</section>
			</FadeInSection>
			<Divider d='120' t='100' m='80' />
			<FadeInSection>

			<section className={` ${s.second} `}>
				<h3>{tr('second_section.title')}</h3>
				<p>
					{tr('second_section.first_paragraph')}
				</p>
				<figure>
					<Img
						src={`${basePicturesUrl}/jpg/SecondSectionImg1${mode}.jpg`}
						alt={tr(`second_section.image1.alt`)}
						fullScreenMode
					/>
					<Img
						src={`${basePicturesUrl}/jpg/SecondSectionImg2${mode}.jpg`}
						alt={tr(`second_section.image2.alt`)}
						fullScreenMode
					/>
					<figcaption>
						{tr('second_section.description')}
					</figcaption>
				</figure>
				<p>
					{tr('second_section.second_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>

			<section className={` ${s.third || ''} `}>
				<Player
					name={'7.Gas_station'}
					page={pageName}
					section={'third'}
				/>
			</section>
			</FadeInSection>
			<Divider d='120' t='100' m='80' />
			<FadeInSection>

			<section className={` ${s.fourth} `}>
				<h2>{tr('fourth_section.title')}</h2>
				<h3>
					{tr('fourth_section.first_paragraph')}
				</h3>
				<p>
					{tr('fourth_section.second_paragraph')}
				</p>
				<figure>
					<Img
						src={`${basePicturesUrl}/jpg/FourthSectionImg1${mode}.jpg`}
						alt={tr(`fourth_section.image1.alt`)}
						fullScreenMode
					/>
					<Img
						src={`${basePicturesUrl}/jpg/FourthSectionImg2${mode}.jpg`}
						alt={tr(`fourth_section.image2.alt`)}
						fullScreenMode
					/>
				</figure>
				<p>
					{tr('fourth_section.third_paragraph')}
				</p>
				<p>
					{tr('fourth_section.fourth_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<Divider d='190' t='100' m='80' />
			<FadeInSection>

			<section className={` ${s.fifth} `}>
				<h2>{tr('fifth_section.title')}</h2>
				<figure>
					<Img
						src={`${basePicturesUrl}/jpg/FifthSectionBg${mode}.jpg`}
						alt={tr(`fifth_section.image1.alt`)}
						fullScreenMode
					/>
					<figcaption>
						{tr(`fifth_section.description`)}
					</figcaption>
				</figure>
				<p>
					{tr('fifth_section.first_paragraph')}	{tr('sixth_section.first_paragraph')}
				</p>
			</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>

			<section className={` ${s.sixth} `}>
				<ul>
					<li>
						<figure>
							<Img
								src={`${basePicturesUrl}/jpg/SixthSectionImage1${mode}.jpg`}
								alt={tr(
									`sixth_section.plane1.image_alt`
								)}
							/>
							<figcaption>
								<a
									target='_blank'
									href={tr(
										`sixth_section.plane1.link`
									)}
								>
									Источник: Wikipedia
								</a>
							</figcaption>
						</figure>

						<h3>
							{tr('sixth_section.plane1.name')}
						</h3>
						<p>
							{tr(
								'sixth_section.plane1.description'
							)}
						</p>
					</li>
					<li>
						<figure>
							<Img
								src={`${basePicturesUrl}/jpg/SixthSectionImage2${mode}.jpg`}
								alt={tr(
									`sixth_section.plane2.image_alt`
								)}
							/>
							<figcaption>
								<a
									target='_blank'
									href={tr(
										`sixth_section.plane2.link`
									)}
								>
									Источник: Wikipedia
								</a>
							</figcaption>
						</figure>

						<h3>
							{tr('sixth_section.plane2.name')}
						</h3>
						<p>
							{tr(
								'sixth_section.plane2.description'
							)}
						</p>
					</li>
					<li>
						<figure>
							<Img
								src={`${basePicturesUrl}/jpg/SixthSectionImage3${mode}.jpg`}
								alt={tr(
									`sixth_section.plane3.image_alt`
								)}
							/>
							<figcaption>
								<a
									target='_blank'
									href={tr(
										`sixth_section.plane3.link`
									)}
								>
									Источник: Wikipedia
								</a>
							</figcaption>
						</figure>

						<h3>
							{tr('sixth_section.plane3.name')}
						</h3>
						<p>
							{tr(
								'sixth_section.plane3.description'
							)}
						</p>
					</li>
					<li>
						<figure>
							<Img
								src={`${basePicturesUrl}/jpg/SixthSectionImage4${mode}.jpg`}
								alt={tr(
									`sixth_section.plane4.image_alt`
								)}
							/>
							<figcaption>
								<a
									target='_blank'
									href={tr(
										`sixth_section.plane4.link`
									)}
								>
									Источник: Wikipedia
								</a>
							</figcaption>
						</figure>

						<h3>
							{tr('sixth_section.plane4.name')}
						</h3>
						<p>
							{tr(
								'sixth_section.plane4.description'
							)}
						</p>
					</li>
					<li>
						<figure>
							<Img
								src={`${basePicturesUrl}/jpg/SixthSectionImage5${mode}.jpg`}
								alt={tr(
									`sixth_section.plane5.image_alt`
								)}
							/>
							<figcaption>
								<a
									target='_blank'
									href={tr(
										`sixth_section.plane5.link`
									)}
								>
									Источник: Wikipedia
								</a>
							</figcaption>
						</figure>

						<h3>
							{tr('sixth_section.plane5.name')}
						</h3>
						<p>
							{tr(
								'sixth_section.plane5.description'
							)}
						</p>
					</li>
					<li>
						<figure>
							<Img
								src={`${basePicturesUrl}/jpg/SixthSectionImage6${mode}.jpg`}
								alt={tr(
									`sixth_section.plane6.image_alt`
								)}
							/>
							<figcaption>
								<a
									target='_blank'
									href={tr(
										`sixth_section.plane6.link`
									)}
								>
									Источник: Wikipedia
								</a>
							</figcaption>
						</figure>

						<h3>
							{tr('sixth_section.plane6.name')}
						</h3>
						<p>
							{tr(
								'sixth_section.plane6.description'
							)}
						</p>
					</li>
				</ul>
			</section>
			</FadeInSection>
			<Divider d='22' t='100' m='40' />
		</div>
	)
}

export default IntermediateAirportAircraft
