import Img from '../Img/Img'
import { useTranslation } from 'react-i18next'
import { forwardRef, useState } from 'react'
import s from './SliderVerticalHorizontal.module.scss'
import { RightArrow, LeftArrow } from './parts'
import { wrap } from 'popmotion'
import { ProducePaths, usePictureMode } from '../../utils'
import { useRef } from 'react'

export const SliderVerticalHorizontal = ({
                             page,
                             section,
                             slides,
                             className,
                             noDescription,
                             endless
                         }) => {
    const [slim, setSlim] = useState(undefined)
    const mode = usePictureMode()
    const { t } = useTranslation()
    // const ref = forwardRef(null)
    const [current, setCurrent] = useState(0)
    const {
        slideNames,
        imageAlts,
        imageDescriptions,
    } = ProducePaths({
        page,
        section,
        slides,
    });

    const getFullScreenSlidersData = (itemIndex = 0) => {
        const fsSliders = slideNames.map((sl, key) => ({
            src: sl.replace('.jpg', 'FS.jpg').replace('/jpg/', '/jpg_new/'),
            alt: t(imageAlts[key])
        }));
        return JSON.stringify(fsSliders.slice(wrap(0, slides, current)).concat(fsSliders.slice(0, wrap(0, slides, current))));
    };

    return (
        <>
            <div
                className={`${className} ${s.switcher}`}
            >
                {!endless ?
                    <>
                        {current !== 0 &&
                        <Img
                            loading='eager'
                            className={`${s.image} ${s.second} ${s.leftImg}`}
                            src={
                                slideNames[
                                    wrap(0, slides, current - 1)
                                    ]
                            }
                            alt={t(
                                imageAlts[
                                    wrap(0, slides, current - 1)
                                    ]
                            )}
                            style={{
                                gridColumn: current + 1 === slides ? '1/span 3' : slim ? '1 / span 4' : '1 / span 3',
                            }}
                        />
                        }
                        <Img
                        onLoad={e => {
                        setSlim(e.target.naturalWidth < e.target.naturalHeight)
                        // console.log(
                        // 	e.target.naturalWidth < 600
                        // 		? 'slim'
                        // 		: 'wide'
                        // )
                        }}
                        loading='eager'
                        className={`${s.active} ${s.image}`}
                        src={
                        slideNames[wrap(0, slides, current)]
                        }
                        alt={t(
                        imageAlts[wrap(0, slides, current)]
                        )}
                        style={{
                        // alignItems: 'stretch',
                        gridColumn:  current + 1 === slides ?
                        '4/span 7'
                        : current === 0 ? '3/span 7'
                        : slim ? '5 / span 4'
                        : '4/span 7'
                        ,
                        }}
                        fullScreenMode
                        dataFullScreen={getFullScreenSlidersData(current)}
                        />
                    {current + 1 !== slides &&
                        <Img
                        loading='eager'
                        className={`${s.image} ${s.fourth} ${s.rightImg}`}
                        src={
                            slideNames[
                            wrap(0, slides, current + 1)
                            ]
                        }
                        alt={t(
                            imageAlts[
                            wrap(0, slides, current + 1)
                            ]
                        )}
                        style={{
                            gridColumn: current === 0 ? '10 / span 3' : slim ? '9 / span 4' : '11 / span 3',
                        }}
                        />
                    }
                    </>
                    :
                    <>
                        <Img
                            loading='eager'
                            className={`${s.image} ${s.second} ${s.leftImg}`}
                            src={
                                slideNames[
                                    wrap(0, slides, current - 1)
                                    ]
                            }
                            alt={t(
                                imageAlts[
                                    wrap(0, slides, current - 1)
                                    ]
                            )}
                            style={{
                                gridColumn: slim ? '1 / span 4' : '1 / span 2',
                            }}
                        />
                        <Img
                            onLoad={e => {
                                setSlim(e.target.naturalWidth < e.target.naturalHeight)
                                // console.log(
                                // 	e.target.naturalWidth < 600
                                // 		? 'slim'
                                // 		: 'wide'
                                // )
                            }}
                            loading='eager'
                            className={`${s.active} ${s.image}`}
                            src={
                                slideNames[wrap(0, slides, current)]
                            }
                            alt={t(
                                imageAlts[wrap(0, slides, current)]
                            )}
                            style={{
                                // alignItems: 'stretch',
                                gridColumn: slim ? '5 / span 4' : '3/span 8',
                            }}
                            fullScreenMode
                            dataFullScreen={getFullScreenSlidersData(current)}
                        />
                        <Img
                            loading='eager'
                            className={`${s.image} ${s.fourth} ${s.rightImg}`}
                            src={
                                slideNames[
                                    wrap(0, slides, current + 1)
                                    ]
                            }
                            alt={t(
                                imageAlts[
                                    wrap(0, slides, current + 1)
                                    ]
                            )}
                            style={{
                                gridColumn: slim ? '9 / span 4' : '11 / span 2',
                            }}
                        />
                    </>
                }
                {!noDescription &&
                    t(
                    imageDescriptions[
                        wrap(0, slides, current)
                        ]
                ) !== '' && (
                    <div className={s.description}>
                        {t(
                            imageDescriptions[
                                wrap(0, slides, current)
                                ]
                        )}
                    </div>
                )}
                <>
                    <LeftArrow
                        className={`${s.left} ${s.arrow} ${s.black}`}
                        onClick={() => {
                                setCurrent(current - 1 >= 0 ?
                                    current_slide => current_slide - 1 : !endless ? current_slide => slides - 1: current_slide => current_slide - 1
                                )
                                console.log(current)
                            }
                        }
                    />
                    <RightArrow
                        className={`${s.right} ${s.arrow} ${s.black}`}
                        onClick={() => {
                                setCurrent(current + 1 !== slides ?
                                    current_slide => current_slide + 1 : !endless ? current_slide => 0 : current_slide => current_slide + 1
                                )
                                console.log(current);
                            }
                        }
                    />
                </>
            </div>
            {/* <pre>{JSON.stringify(ref, null, 2)}</pre> */}
        </>
    )
}
