import { capitalize, usePageName } from 'utils'
import './TripPreparation.scss'
import { withTranslation } from 'react-i18next'
import { usePictureMode } from '../../utils/usePictureMode'
import { getLanguage } from '../../utils/customLanguageDetector'
import 'video.js/dist/video-js.css'
import { Slider1 } from '../../components/Sliders/Slider1'
import Img from 'components/Img/Img'
import { Divider } from 'components/Divider'
import AuthorLayout from 'components/AuthorLayout/AuthorLayout'
import Quotes from 'components/Quotes/Quotes'
import ImgDesc from 'components/ImgDesc/ImgDesc'
import { Switcher as DeskSwitcher } from 'components/Sliders/Desk/Switcher'
import { SwitcherTab as TabletSwitcher } from 'components/Sliders/Tab/SwitcherTab'
import { Player } from 'components/VideoJS/Player'
import { useWindowMode } from '../../utils/useWindowMode'
import SwitchTwoImages from 'components/SwitchTwoImages/SwitchTwoImages'
import FadeInSection from 'components/FadeInOut/FadeSection'
import DesktopSlider from "../../components/Sliders/NewSliders/Desk/Desk";
import TabletSlider from "../../components/Sliders/NewSliders/Tablet/Tablet";
import MobileSlider from "../../components/Sliders/NewSliders/Mobile";


const TripPreparation = ({ t }) => {
	const pageName = usePageName()
	const tr = slice =>
		t(`pages.${pageName}.${slice}`)

	const mode = usePictureMode()
	const basePicturesUrl = `/images/` + pageName
	const seventhSliderConfig = {
		className: 'slider',
		page: pageName,
		section: 'seventh',
		slides: 5,
	}
	const size = useWindowMode()

	const fourthSectionConfig = {
		className: "slider",
		page: pageName,
		section: "fourth",
		slides: 3,
	};

	return (
		<section className={pageName}>
			<FadeInSection>
				<section className='pagePreview'>
					<h1 className='title'>
						{tr('first_section.title')}
					</h1>
					<Img
						className='image'
						src={`${basePicturesUrl}/jpg/FirstSectionBg${mode}.jpg`}
						alt={tr('first_section.alts.image1')}
					/>
				</section>
			</FadeInSection>
			<Divider d='120' t='100' m='80' />
			<FadeInSection>
				<section className='secondSection'>
					<h2>{tr('second_section.title')}</h2>
					<h3>
						{tr('second_section.first_paragraph')}
					</h3>
					<AuthorLayout
						pageName={pageName}
						section={'Second'}
					/>
					<Quotes
						pageName={pageName}
						section={'Second'}
						paragraph={'fourth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='232' t='138' m='100' />
			<FadeInSection>
				<section className='thirdSection'>
					<h2>{tr('third_section.title')}</h2>
					<p className='first_paragraph'>
						{tr('third_section.first_paragraph')}
					</p>
					<Img
						className='image'
						alt={tr('third_section.alts.image1')}
						src={`${basePicturesUrl}/jpg/ThirdSectionBg${mode}.jpg`}
						fullScreenMode
					/>
					<em>
						{tr(
							'third_section.image.description'
						)}
					</em>
					<p className='second_paragraph'>
						{tr('third_section.second_paragraph')}
					</p>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				{size === 'desktop' ? (
					<DesktopSlider {...fourthSectionConfig} id={'fourth_section_slider'}  borderSliders={2}/>
				) : size === 'tablet' ? (
					<TabletSlider {...fourthSectionConfig} id={"fourth_section_slider"} borderSliders={1} isLinear whiteArrows/>
				) : (
					<MobileSlider
						{...fourthSectionConfig}
						id={"tenth_section_slider"}
						borderSliders={2}
						isArrows={true}
						isLinear={true}
						hidePull={true}
					/>
				)}
			</FadeInSection>
			<Divider d='70' t='100' m='80' />
			<FadeInSection>
				<section className='fifthSection'>
					<h2>{tr('fifth_section.title')}</h2>
					<p>
						{tr('fifth_section.first_paragraph')}
					</p>
					<ImgDesc
						section='fifth'
						pageName={pageName}
						fullWidth
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='100' m='40' />
			<FadeInSection>
				<section className='sixthSection'>
					<p>
						{tr('sixth_section.first_paragraph')}
					</p>
					<ImgDesc
						section='sixth'
						pageName={pageName}
						map
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>
				<section className='seventhSection'>
					<p>
						{tr(
							'seventh_section.first_paragraph'
						)}
					</p>
					{size === 'desktop' ? (
						<DeskSwitcher
							{...seventhSliderConfig}
							highActive
						/>
					) : size === 'tablet' ? (
						<TabletSwitcher
                            styleSliders = {{maxHeight: '500px'}}
							// whiteArrows
							{...seventhSliderConfig}
						/>
					) : (
							<MobileSlider
								{...seventhSliderConfig}
								id={"seventh_section_slider"}
								borderSliders={2}
							/>

					)}
				</section>
			</FadeInSection>
			<Divider d='100' t='100' m='80' />
			<FadeInSection>
				<section className='eighthSection'>
					<p>
						{tr('eighth_section.first_paragraph')}
					</p>
					<Player
						name={'4.Airplane'}
						page={pageName}
						section={'eighth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className='ninthSection'>
					<p>
						{tr('ninth_section.first_paragraph')}
					</p>
					<SwitchTwoImages
						pageName={pageName}
						section={'ninth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='314' t='100' m='80' />
			<FadeInSection>
				<section className='tenthSection'>
					<h2>{tr('tenth_section.title')}</h2>
					<p>
						{tr('tenth_section.first_paragraph')}
					</p>
					<Img
						className='image'
						alt={tr('tenth_section.alts.image1')}
						src={`${basePicturesUrl}/jpg/TenthSectionMap_${getLanguage()}${mode}.jpg`}
					/>
					<ul>
						<li>{tr('tenth_section.ul.li_1')}</li>
						<li>{tr('tenth_section.ul.li_2')}</li>
						<li>{tr('tenth_section.ul.li_3')}</li>
					</ul>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='40' />
			<FadeInSection>
				<section className='eleventhSection'>
					<p className='first_paragraph'>
						{tr(
							'eleventh_section.first_paragraph'
						)}
					</p>
					<ImgDesc
						section='eleventh'
						pageName={pageName}
						left
						normal
						fullScreenMode
                        imgClassName={'img500-400-343'}
					/>
					<p className='second_paragraph'>
						{tr(
							'eleventh_section.second_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='100' t='40' m='40' />
			<FadeInSection>
				<section className='twelfthSection'>
					<p>
						{tr(
							'twelfth_section.first_paragraph'
						)}
					</p>
					<ImgDesc
						section='twelfth'
						pageName={pageName}
						left
						normal
						fullScreenMode
                        imgClassName={'img500-400-343'}
					/>
				</section>
			</FadeInSection>
			<Divider d='191' t='100' m='80' />
			<FadeInSection>
				<section className='thirteenthSection'>
					<h2>
						{tr('thirteenth_section.title')}
					</h2>
					<p className='first_paragraph'>
						{tr(
							'thirteenth_section.first_paragraph'
						)}
					</p>
					<p className='second_paragraph'>
						{tr(
							'thirteenth_section.second_paragraph'
						)}
					</p>
					<SwitchTwoImages
						pageName={pageName}
						section={'thirteenth'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className='fourteenthSection'>
					<p>
						{tr(
							'fourteenth_section.first_paragraph'
						)}
					</p>
					<ImgDesc
						section='fourteenth'
						pageName={pageName}
						normal
						fullScreenMode
                        imgClassName={'img500-400-343'}
					/>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className='fifteenthSection'>
					<p className='first_paragraph'>
						{tr(
							'fifteenth_section.first_paragraph'
						)}
					</p>
					<p className='second_paragraph'>
						{tr(
							'fifteenth_section.second_paragraph'
						)}
					</p>
					<ImgDesc
						section='fifteenth'
						pageName={pageName}
						normal
						fullScreenMode
                        imgClassName={'img500-400-343'}
					/>
					<p className='thirdth_paragraph'>
						{tr(
							'fifteenth_section.thirdth_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='189' t='100' m='80' />
			<FadeInSection>
				<section className='sixteenthSection'>
					<h2>{tr('sixteenth_section.title')}</h2>
					<p>
						{tr(
							'sixteenth_section.first_paragraph'
						)}
					</p>
					<Img
						className={'image'}
						src={`${basePicturesUrl}/jpg/SixteenthSectionBg${mode}.jpg`}
						alt={tr(
							`sixteenth_section.alts.image1`
						)}
                        // imgClassName={'img500-400-343'}
					/>
				</section>
			</FadeInSection>
			<Divider d='120' t='100' m='40' />
			<FadeInSection>
				<section className='seventeenthSection'>
					<h2>
						{tr('seventeenth_section.title')}
					</h2>
					<p className='first_paragraph'>
						{tr(
							'seventeenth_section.first_paragraph'
						)}
					</p>
					<p className='second_paragraph'>
						{tr(
							'seventeenth_section.second_paragraph'
						)}
					</p>
					<Img
						className={'image'}
						src={`${basePicturesUrl}/jpg/SeventeenthSectionBg${mode}.jpg`}
						alt={tr(
							`seventeenth_section.alts.image1`
						)}
					/>
				</section>
			</FadeInSection>
			<Divider d='190' t='100' m='80' />
			<FadeInSection>
				<section className='eighteenthSection'>
					<h2>
						{tr('eighteenth_section.title')}
					</h2>
					<h4>
						{tr(
							'eighteenth_section.first_paragraph'
						)}
					</h4>
					<p>
						{tr(
							'eighteenth_section.second_paragraph'
						)}
					</p>
					<div className='firstImgBlock'>
						<Img
							className={'image'}
							src={`${basePicturesUrl}/jpg/EighteenthSectionImg1${mode}.jpg`}
							alt={tr(
								`eighteenth_section.img_Blocks.image1.alt`
							)}
						/>
						<h4>
							{tr(
								'eighteenth_section.img_Blocks.image1.title'
							)}
						</h4>
						<p>
							{tr(
								'eighteenth_section.img_Blocks.image1.first_paragraph'
							)}
						</p>
					</div>
					<div className='secondImgBlock'>
						<Img
							className={'image'}
							src={`${basePicturesUrl}/jpg/EighteenthSectionImg2${mode}.jpg`}
							alt={tr(
								`eighteenth_section.img_Blocks.image2.alt`
							)}
						/>
						<h4>
							{tr(
								'eighteenth_section.img_Blocks.image2.title'
							)}
						</h4>
						<p>
							{tr(
								'eighteenth_section.img_Blocks.image2.first_paragraph'
							)}
						</p>
					</div>
					<div className='thirdImgBlock'>
						<Img
							className={'image'}
							src={`${basePicturesUrl}/jpg/EighteenthSectionImg3${mode}.jpg`}
							alt={tr(
								`eighteenth_section.img_Blocks.image3.alt`
							)}
						/>
						<h4>
							{tr(
								'eighteenth_section.img_Blocks.image3.title'
							)}
						</h4>
						<p>
							{tr(
								'eighteenth_section.img_Blocks.image3.first_paragraph'
							)}
						</p>
					</div>
					<div className='fourthImgBlock'>
						<Img
							className={'image'}
							src={`${basePicturesUrl}/jpg/EighteenthSectionImg4${mode}.jpg`}
							alt={tr(
								`eighteenth_section.img_Blocks.image4.alt`
							)}
						/>
						<h4>
							{tr(
								'eighteenth_section.img_Blocks.image4.title'
							)}
						</h4>
						<p>
							{tr(
								'eighteenth_section.img_Blocks.image4.first_paragraph'
							)}
						</p>
					</div>
					<div className='fifthImgBlock'>
						<Img
							className={'image'}
							src={`${basePicturesUrl}/jpg/EighteenthSectionImg5${mode}.jpg`}
							alt={tr(
								`eighteenth_section.img_Blocks.image5.alt`
							)}
						/>
						<h4>
							{tr(
								'eighteenth_section.img_Blocks.image5.title'
							)}
						</h4>
						<p>
							{tr(
								'eighteenth_section.img_Blocks.image5.first_paragraph'
							)}
						</p>
					</div>
				</section>
			</FadeInSection>
			<Divider d='100' t='80' m='80' />
			<FadeInSection>
				<section className='ninteenthSection'>
					<div className='first_block'>
						<h4>
							{tr(
								'ninteenth_section.first_block.first_title'
							)}
						</h4>
						<p className='first_paragraph'>
							{tr(
								'ninteenth_section.first_block.first_paragraph'
							)}
						</p>

						<h4>
							{tr(
								'ninteenth_section.first_block.second_title'
							)}
						</h4>
						<p className='second_paragraph'>
							{tr(
								'ninteenth_section.first_block.second_paragraph'
							)}
						</p>

						<h4>
							{tr(
								'ninteenth_section.first_block.third_title'
							)}
						</h4>
						<p>
							{tr(
								'ninteenth_section.first_block.third_paragraph'
							)}
						</p>
					</div>

					<div className='second_block'>
						<h4>
							{tr(
								'ninteenth_section.second_block.first_title'
							)}
						</h4>
						<ul>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_1'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_2'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_3'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_4'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_5'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_6'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_7'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_8'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_9'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_10'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_11'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_12'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_13'
								)}
							</li>
							<li>
								{tr(
									'ninteenth_section.second_block.ul.li_14'
								)}
							</li>
						</ul>
					</div>
					<Img
						className={'image'}
						src={`${basePicturesUrl}/jpg/NinteenthSectionBg${mode}.jpg`}
						alt={tr(
							`ninteenth_section.alts.image1`
						)}
					/>
					<p>
						{tr(
							'ninteenth_section.first_paragraph'
						)}
					</p>
				</section>
			</FadeInSection>
			<Divider d='120' t='80' m='40' />
		</section>
	)
};

export default withTranslation()(TripPreparation)
